import React, { useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Loading, Table } from "Components";
import { Button, Menu, MenuItem } from "@mui/material";
import {
  LuUser2,
  LuCreditCard,
  LuShoppingCart,
  LuStickyNote,
  LuChevronDown,
  LuExternalLink,
  LuChevronLeft,
} from "react-icons/lu";
import moment from "moment";
import { useAuth } from "Context/AuthContext";
import { switchStatusColor } from "Helpers/switchStatusColor";
import {
  useGetOrderDetailsById,
  useUpdateOrderStatus,
} from "./useOrderDetailsApis";
import "./style.css";

export const DashboardOrderDetails = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  let { orderId } = useParams();
  const [menuOpen, setMenuOpen] = useState(false);

  const { data: getOrderById, status, error } = useGetOrderDetailsById(orderId);
  const mutation = useUpdateOrderStatus(orderId);
  const updateStatusById = async (newStatus) => {
    try {
      const response = await mutation.mutateAsync({
        data: { status: newStatus },
      });
      if (!response.success) {
        alert(response.message);
      }
      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  if (status === "pending") {
    return <Loading />;
  }

  if (status === "error") {
    if (
      error?.message === "Your sessions token has expired !! Login again" ||
      error?.message === "Unauthorized to access" ||
      error?.message === "Invalid Token" ||
      error?.message === "The token owner no longer exist"
    ) {
      alert(error?.message);
      logout();
      return <Navigate to={"/login"} />;
    }
    if (error?.message === "Order not found") {
      return <Navigate to={"/404"} />;
    }
    return <div>{error?.message}</div>;
  }

  if (status === "success") {
    return (
      <div className="dashboard_container">
        <div className="flex_c gap-40">
          <div className="flex_r dashboard_product_title">
            <div className="flex_r space-between dashboardorder_container">
              <div
                className="flex_r "
                style={{ alignItems: "center", gap: "10px" }}
              >
                <LuChevronLeft style={{ fontSize: "25px" }} onClick={goBack} />
                <h1 className="dashboard_title">Order Details</h1>
              </div>
              <div>
                <Button
                  id={"order-status-button"}
                  aria-controls={"order-status-menu"}
                  aria-haspopup="true"
                  onClick={(e) => setMenuOpen(true)}
                  endIcon={<LuChevronDown />}
                  style={{
                    fontSize: "14px",
                    color: switchStatusColor(getOrderById.status),
                    border: `2px solid ${switchStatusColor(
                      getOrderById.status
                    )}`,
                  }}
                >
                  {getOrderById.status}
                </Button>
                <Menu
                  id="order-status-menu"
                  anchorEl={document.getElementById("order-status-button")}
                  open={menuOpen}
                  onClose={() => setMenuOpen(false)}
                  MenuListProps={{
                    "aria-labelledby": "order-status-button",
                  }}
                >
                  <MenuItem
                    onClick={async () => {
                      setMenuOpen(false);
                      await updateStatusById("processing");
                    }}
                    style={{ color: "#345EFF" }}
                  >
                    Processing
                  </MenuItem>

                  <MenuItem
                    onClick={async () => {
                      setMenuOpen(false);
                      await updateStatusById("completed");
                    }}
                    style={{ color: "#2CDA53" }}
                  >
                    Completed
                  </MenuItem>
                  <MenuItem
                    onClick={async () => {
                      setMenuOpen(false);
                      await updateStatusById("canceled");
                    }}
                    style={{ color: "#EE1D52" }}
                  >
                    Canceled
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </div>
          <div className="flex_r  dashboard_order_serie">
            <div className="flex_c gap-20 dashbord_heading">
              <h1>Order no: #{getOrderById._id}</h1>
              <p>
                Placed On{" "}
                {moment(getOrderById.createdAt).format("YYYY-MM-DD HH:mm:ss")}{" "}
              </p>
            </div>
            <div className="dashboard_order_total_price">
              <p>
                Total:{" "}
                <span className="db_costumize_order_price">
                  ${getOrderById.total}
                </span>
              </p>
            </div>
          </div>
          <div className="flex_r dashbord_order_info w-100">
            <div
              className=" flex_r flex_wrap w-100"
              style={{ justifyContent: "center" }}
            >
              <div className="flex_r gap-20 dashboard_order_customer_details">
                <div>
                  <LuUser2 className="dashboard_order_info_icons" />
                </div>
                <div className="flex_c gap-20">
                  <h1 className="title_details_style">Customer details</h1>
                  <div className="flex_r gap-40 align_items_center details_covers">
                    <h1>Name:</h1>
                    <p>{getOrderById.customerInfo.name}</p>
                  </div>
                  <div className="flex_r gap-40 align_items_center details_covers">
                    <h1>Email: </h1>
                    <p>{getOrderById.customerInfo.email || "-"}</p>
                  </div>
                  <div className="flex_r gap-40 align_items_center details_covers">
                    <h1>Phone:</h1>
                    <p>{getOrderById.customerInfo.phone}</p>
                  </div>
                </div>
              </div>
              <div className="flex_r gap-20 dashboard_order_customer_details">
                <div>
                  <LuShoppingCart className="dashboard_order_info_icons" />
                </div>
                <div className="flex_c gap-20">
                  <h1 className="title_details_style">Shipping details</h1>
                  <div className="flex_r gap-40 align_items_center details_covers">
                    <h1>City:</h1>
                    <p>{getOrderById.shippingAddress.city}</p>
                  </div>
                  <div className="flex_r gap-40 align_items_center details_covers">
                    <h1>Street Address:</h1>
                    <p> {getOrderById.shippingAddress.street}</p>
                  </div>
                </div>
              </div>
              <div className="flex_r gap-20 dashboard_order_customer_details">
                <div>
                  <LuCreditCard className="dashboard_order_info_icons" />
                </div>
                <div className="flex_c gap-20">
                  <h1 className="title_details_style">Billing details</h1>
                  <div className="flex_r gap-40 align_items_center space-between details_covers">
                    <h1>Payment Method:</h1>
                    <p>{getOrderById.paymentMethod}</p>
                  </div>
                  <div className="flex_r gap-40 align_items_center details_covers">
                    <h1>City: </h1>
                    <p>{getOrderById.billingAddress.city}</p>
                  </div>
                  <div className="flex_r gap-40 align_items_center details_covers">
                    <h1>Street Address:</h1>
                    <p>{getOrderById.billingAddress.street}</p>
                  </div>
                </div>
              </div>
              <div className="flex_r gap-20 dashboard_order_customer_details">
                <div>
                  <LuStickyNote className="dashboard_order_info_icons" />
                </div>
                <div className="flex_c gap-20">
                  <h1 className="title_details_style">Note</h1>
                  <div className="flex_r gap-40 align_items_center details_covers">
                    <p>{getOrderById.note}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard_table_container">
            <Table heading={["PRODUCT DETAILS", "QUANTITY", "TOTAL", " "]}>
              {getOrderById?.products.map((product) => (
                <tr key={product._id}>
                  <td>
                    <div className="flex_r gap-20 align_items_center">
                      <img
                        src={product.image}
                        className="dashboard_table_row_img"
                        alt="#"
                      />
                      {product.name}
                    </div>
                  </td>
                  <td> {product.quantity}</td>
                  <td>{product.total}</td>
                  <td>
                    <LuExternalLink
                      style={{ fontSize: "20px" }}
                      onClick={() =>
                        navigate(`/dashboard/products/${product.productId}`)
                      }
                    />
                  </td>
                </tr>
              ))}
            </Table>
            <div className="flex_r gap-20 dashboard_order_amount ">
              <div className="width-400">
                <div className=" flex_c gap-20 border-bottom padding-15">
                  <div className="flex_r space_between ">
                    <h1 className="dashboard_order_name">Sub Total</h1>
                    <p className="dashboard_order_name">
                      ${getOrderById.subTotal}
                    </p>
                  </div>
                  <div className="flex_r space_between">
                    <h1 className="dashboard_order_name"> Shipping</h1>
                    <p className="dashboard_order_name">
                      ${getOrderById.shipping}
                    </p>
                  </div>
                </div>
                <div className="flex_r border-bottom space_between padding-15">
                  <h1 className="bold dashboard_order_name">Total</h1>
                  <p className="bold dashboard_order_name">
                    ${getOrderById.total}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
