import { baseURL } from "Components/Common/constance";
import axios from "axios";

const productsdAnalyticsEndPoints = "/api/products/admin/analytics";
const productsTableEndPoints = "/api/products/admin/search";
const productUpdateEndPoint = "/api/products";
const productGetByIdEndPoint = "/api/products/admin";
const deleteProductEndPoints = "/api/products/";
const uploadCSVEndPoints = "/api/products/csv";
const addProductEndPoint = "/api/products";

export const getProductsAnalytics = async (authToken) => {
  try {
    const response = await axios.get(
      `${baseURL}${productsdAnalyticsEndPoints}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    return response.data.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getProductsTable = async (authToken, query) => {
  try {
    const response = await axios.get(`${baseURL}${productsTableEndPoints}`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      params: query,
    });
    return response.data.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getProductById = async (authToken, productId) => {
  try {
    const response = await axios.get(`${baseURL}${productGetByIdEndPoint}/${productId}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        }
      });
    return response.data.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const updateProduct = async (authToken, productId, data) => {
  try {
    const response = await axios.put(
      `${baseURL}${productUpdateEndPoint}/${productId}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteProduct = async (authToken, productId) => {
  try {
    const response = await axios.delete(
      `${baseURL}${deleteProductEndPoints}${productId}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const uploadCSV = async (authToken, formData) => {
  try {
    const response = await axios.post(
      `${baseURL}${uploadCSVEndPoints}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const addProduct = async (authToken, formData) => {
  try {
    const response = await axios.post(
      `${baseURL}${addProductEndPoint}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
