import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { color } from 'theme';
import { Input, Button, Loading } from 'Components';
import './style.css';
import { useVerifyOtpApi } from './useVerifyOtpApi';

export const CodeVerification = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false)
    const [code, setCode] = useState("");
    const { mutateAsync: verifyOtp } = useVerifyOtpApi();

    const handleChange = (e) => {
        setCode(e.target.value)
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const response = await verifyOtp({ otp: code });
            if (!response.success) {
                alert(response.message);
            }
            setIsLoading(false);
            // navigate('/onboarding');
            navigate('/dashboard/products');
            return response;
        } catch (error) {
            console.error(error);
            return null;
        }
    }
    return (
        <div className='main_container'>
            {isLoading ? <Loading /> : (
                <div className='flex_c gap-40 auth_page'>
                    <div className='flex_c gap-20'>
                        <h1>Verification</h1>
                        <p style={{ color: color.textColor }} >Verify your code.</p>
                    </div>
                    <form className='flex_c gap-20' onSubmit={handleSubmit} >
                        <Input
                            type="text"
                            label="Verification Code"
                            placeholder={"****"}
                            className="login_input"
                            onChange={handleChange}
                            value={code}
                        />
                        <Button name="Verify Code" type="submit" />
                    </form>
                </div>
            )}
        </div>
    )
}
