import React from "react";
import "./style.css";

export const Loading = () => {
  return (
    <div className="loading_container">
      <div className="loading">Loading&#8230;</div>
    </div>
  );
};
