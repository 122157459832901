import React, { useEffect, useState } from "react";
import moment from "moment";
import { Navigate, useNavigate } from "react-router-dom";
import { Loading, Table } from "Components";
import { Button, Menu, MenuItem } from "@mui/material";
import { LuChevronDown, LuExternalLink } from "react-icons/lu";
import { switchStatusColor } from "Helpers/switchStatusColor";
import { switchSortedByValue } from "Helpers/switchSortedByValue";
import { useOrderApis, useUpdateOrderStatus } from "./UseOrderApis";
import { useAuth } from "Context/AuthContext";
import "./style.css";

export const DashboardOrders = () => {
    const navigate = useNavigate();
    const { logout } = useAuth();

    const [orderMenu, setOrderMenu] = useState(null);
    const [selectedOrder, setSelectedOrder] = useState(null);

    const [selectedOption, setSelectedOption] = useState("all");

    const [filterMenu, setFilterMenu] = useState(false);
    const [filteredBy, setFilteredBy] = useState("all");
    const handleFilteredBy = (option) => {
        setFilteredBy(option);
        setFilterMenu(false);
    };

    const [tableCurrentPage, setTableCurrentPage] = useState(1);
    const tableLimit = 20;

    const {
        data: orderData,
        isLoading,
        status,
        error,
        refetch,
    } = useOrderApis({
        limit: tableLimit,
        page: tableCurrentPage,
        status: selectedOption,
        time: filteredBy,
    });
    useEffect(() => {
        refetch();
    }, [tableCurrentPage, selectedOption, filteredBy, refetch]);

    const mutation = useUpdateOrderStatus();

    const updateStatusById = async (id, newStatus) => {
        try {
            const response = await mutation.mutateAsync({
                orderId: id,
                data: { status: newStatus },
            });
            if (!response.success) {
                alert(response.message);
            }
            return response;
        } catch (error) {
            return null;
        }
    };

    if (status === "pending") {
        return (<Loading />)
    }

    if (status === "error") {
        if (
            error?.message === "Your sessions token has expired !! Login again" ||
            error?.message === "Unauthorized to access" ||
            error?.message === "Invalid Token" ||
            error?.message === "The token owner no longer exist"
        ) {
            alert(error?.message);
            logout();
            return (<Navigate to={"/login"} />);
        }
        return (<div>{error?.message}</div>);
    }

    if (status === "success") {
        return (
            <div className="flex_c gap-30">
                <div className="flex_r gap-40" style={{ alignItems: "center" }}>
                    <h1 className="dashboard_title">
                        Order: {orderData?.totalItems || "-"}{" "}
                    </h1>
                    <Button
                        id="products-sort-button"
                        endIcon={<LuChevronDown />}
                        aria-controls={filterMenu ? "products-sort-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={filterMenu ? "true" : undefined}
                        onClick={(e) => setFilterMenu(true)}
                        variant="outlined"
                        className="outlined-btn"
                    >
                        {switchSortedByValue(filteredBy)}
                    </Button>
                    <Menu
                        id="products-sort-menu"
                        anchorEl={document.getElementById("products-sort-button")}
                        open={filterMenu}
                        onClose={() => setFilterMenu(false)}
                        MenuListProps={{
                            "aria-labelledby": "products-sort-button",
                        }}
                    >
                        <MenuItem onClick={() => handleFilteredBy("all")}>All Times</MenuItem>
                        <MenuItem onClick={() => handleFilteredBy("today")}>Today</MenuItem>
                        <MenuItem onClick={() => handleFilteredBy("lastWeek")}>
                            Last Week
                        </MenuItem>
                        <MenuItem onClick={() => handleFilteredBy("lastMonth")}>
                            Last Month
                        </MenuItem>
                        <MenuItem onClick={() => handleFilteredBy("last6Months")}>
                            Last 6 months
                        </MenuItem>
                        <MenuItem onClick={() => handleFilteredBy("lastYear")}>
                            Last Year
                        </MenuItem>
                    </Menu>
                </div>

                <div className="flex_r flex_wrap dashboard_order_options">
                    <h4
                        className={selectedOption === "all" ? "selected" : ""}
                        onClick={
                            selectedOption === "all" ? null : () => setSelectedOption("all")
                        }
                    >
                        All
                    </h4>
                    <h4
                        className={selectedOption === "pending" ? "selected" : ""}
                        onClick={
                            selectedOption === "pending"
                                ? null
                                : () => setSelectedOption("pending")
                        }
                    >
                        Pending
                    </h4>
                    <h4
                        className={selectedOption === "processing" ? "selected" : ""}
                        onClick={
                            selectedOption === "processing"
                                ? null
                                : () => setSelectedOption("processing")
                        }
                    >
                        Processing
                    </h4>
                    <h4
                        className={selectedOption === "completed" ? "selected" : ""}
                        onClick={
                            selectedOption === "completed"
                                ? null
                                : () => setSelectedOption("completed")
                        }
                    >
                        Completed
                    </h4>
                    <h4
                        className={selectedOption === "canceled" ? "selected" : ""}
                        onClick={
                            selectedOption === "canceled"
                                ? null
                                : () => setSelectedOption("canceled")
                        }
                    >
                        Canceled
                    </h4>
                </div>

                <div className="flex_c gap-20">
                    <div className="dashboard_table_container">
                        <Table
                            heading={[
                                "ORDER NB",
                                "DATE",
                                "CUSTOMER NAME",
                                "COST",
                                "STATUS",
                                "",
                            ]}
                            currentPage={orderData?.currentPage}
                            totalPages={orderData?.totalPages}
                            onSelectPage={(page) => setTableCurrentPage(page)}
                            onNextPage={() => setTableCurrentPage(tableCurrentPage + 1)}
                            onPreviousPage={() => setTableCurrentPage(tableCurrentPage - 1)}
                        >
                            {isLoading ? (
                                <Loading />
                            ) : (
                                orderData?.items.map((order) => (
                                    <tr key={order._id}>
                                        <td>#{order._id}</td>
                                        <td>
                                            {moment(order.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                                        </td>
                                        <td>{order.customerInfo.name}</td>
                                        <td>${order.total}</td>
                                        <td>
                                            <Button
                                                id={`order-status-button-${order._id}`}
                                                aria-controls={
                                                    selectedOrder === order._id
                                                        ? `order-status-menu-${order._id}`
                                                        : undefined
                                                }
                                                aria-haspopup="true"
                                                aria-expanded={
                                                    selectedOrder === order._id ? "true" : undefined
                                                }
                                                onClick={(e) => {
                                                    setSelectedOrder(order._id);
                                                    setOrderMenu("status");
                                                }}
                                                endIcon={<LuChevronDown />}
                                                style={{
                                                    fontSize: "14px",
                                                    color: switchStatusColor(order.status),
                                                    border: `2px solid ${switchStatusColor(order.status)}`,
                                                }}
                                            >
                                                {order.status}
                                            </Button>
                                            <Menu
                                                id={`order-status-menu-${order._id}`}
                                                anchorEl={document.getElementById(
                                                    `order-status-button-${order._id}`
                                                )}
                                                open={
                                                    selectedOrder === order._id && orderMenu === "status"
                                                }
                                                onClose={() => setSelectedOrder(null)}
                                                MenuListProps={{
                                                    "aria-labelledby": `order-status-button-${order._id}`,
                                                }}
                                            >
                                                <MenuItem
                                                    onClick={() => {
                                                        updateStatusById(order._id, "processing");
                                                        setSelectedOrder(null);
                                                    }}
                                                    style={{ color: "#345EFF" }}
                                                >
                                                    Processing
                                                </MenuItem>
                                                <MenuItem
                                                    onClick={() => {
                                                        updateStatusById(order._id, "completed");
                                                        setSelectedOrder(null);
                                                    }}
                                                    style={{ color: "#2CDA53" }}
                                                >
                                                    Completed
                                                </MenuItem>
                                                <MenuItem
                                                    onClick={() => {
                                                        updateStatusById(order._id, "canceled");
                                                        setSelectedOrder(null);
                                                    }}
                                                    style={{ color: "#EE1D52" }}
                                                >
                                                    Canceled
                                                </MenuItem>
                                            </Menu>
                                        </td>
                                        <td>
                                            <LuExternalLink
                                                onClick={() => navigate(`/dashboard/orders/${order._id}`)}
                                            />
                                        </td>
                                    </tr>
                                ))
                            )}
                        </Table>
                    </div>
                </div>
            </div>
        );
    }

};
