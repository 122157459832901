import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, Button } from 'Components';
import { color } from 'theme';
import './style.css';

export const ResetPassword = () => {
    const navigate = useNavigate();
    const [passData, setPassData] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault()
        navigate('/check-email');
    }

    return (
        <div className='main_container'>
            <div className='flex_c gap-40 auth_page'>
                <div className='flex_c gap-20'>
                    <h1 >Reset Your Password</h1>
                    <p style={{ color: color.textColor }} >Enter your email and we'll send you a link to reset your password.<br />Please  check it.</p>
                </div>

                <form className='flex_c gap-20' onSubmit={handleSubmit}>
                    <Input
                        type='email'
                        className="login_input"
                        label="Email"
                        placeholder="example@gmail.com"
                        onChange={(e) => {
                            setPassData(e.target.value)
                        }}
                        value={passData}
                    />
                    <Button name="Send" type="submit" />
                </form>
            </div>
        </div>
    )
}
