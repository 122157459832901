import React, { createContext, useState, useContext, useEffect } from 'react';
import { openDB } from 'idb';
import { baseURL } from 'Components/Common/constance';

const CartContext = createContext();

export const useCart = () => {
    return useContext(CartContext);
};

export const CartProvider = ({ children }) => {
    const [cartItems, setCartItems] = useState([]);

    useEffect(() => {
        const loadCartItems = async () => {
            const db = await openDB('my-cart', 1, {
                upgrade(db) {
                    db.createObjectStore('cart', { keyPath: '_id' });
                },
            });

            const tx = db.transaction('cart', 'readonly');
            const store = tx.objectStore('cart');
            const items = await store.getAll();
            setCartItems(items);
        };

        loadCartItems();
    }, []);

    const addToCart = async (product) => {
        const db = await openDB('my-cart', 1);
        const tx = db.transaction('cart', 'readwrite');
        const store = tx.objectStore('cart');

        const existingProduct = await store.get(product._id);

        if (existingProduct) {
            if (product.stock < existingProduct.quantity) {
                alert("You added more than the product stock!");
                return;
            }
            const updatedProduct = {
                ...existingProduct,
                quantity: existingProduct.quantity + 1,
            };
            await store.put(updatedProduct);

            const updatedCartItems = cartItems.map(item =>
                item._id === updatedProduct._id ? updatedProduct : item
            );
            setCartItems(updatedCartItems);
        } else {
            if (product.stock < product.quantity) {
                alert("You added more than the product stock!");
                return;
            }
            await store.add({ ...product, quantity: 1 });
            setCartItems([...cartItems, { ...product, quantity: 1 }]);
        }
        alert("The product added to the cart!")
    };

    const incrementQuantity = async (productId) => {
        const db = await openDB('my-cart', 1);
        const tx = db.transaction('cart', 'readwrite');
        const store = tx.objectStore('cart');

        const existingProduct = await store.get(productId);
        if (existingProduct) {
            if (existingProduct.stock < existingProduct.quantity + 1) {
                alert("You added more than the product stock!");
                return;
            }
            const updatedProduct = {
                ...existingProduct,
                quantity: existingProduct.quantity + 1,
            };
            await store.put(updatedProduct);

            const updatedCartItems = cartItems.map(item =>
                item._id === updatedProduct._id ? updatedProduct : item
            );
            setCartItems(updatedCartItems);
        }
    };

    const decrementQuantity = async (productId) => {
        const db = await openDB('my-cart', 1);
        const tx = db.transaction('cart', 'readwrite');
        const store = tx.objectStore('cart');

        const existingProduct = await store.get(productId);
        if (existingProduct && existingProduct.quantity > 1) {
            const updatedProduct = {
                ...existingProduct,
                quantity: existingProduct.quantity - 1,
            };
            await store.put(updatedProduct);

            const updatedCartItems = cartItems.map(item =>
                item._id === updatedProduct._id ? updatedProduct : item
            );
            setCartItems(updatedCartItems);
        }
    };

    const removeFromCart = async (productId) => {
        const db = await openDB('my-cart', 1);
        const tx = db.transaction('cart', 'readwrite');
        const store = tx.objectStore('cart');
        await store.delete(productId);
        const updatedItems = cartItems.filter((item) => item._id !== productId);
        setCartItems(updatedItems);
    };

    const clearCart = async () => {
        const db = await openDB('my-cart', 1);
        const tx = db.transaction('cart', 'readwrite');
        const store = tx.objectStore('cart');
        await store.clear();
        setCartItems([]);
    };

    const syncCartWithBackend = async () => {
        const db = await openDB('my-cart', 1);
        const tx = db.transaction('cart', 'readonly');
        const store = tx.objectStore('cart');
        const cartItems = await store.getAll();

        const cartData = cartItems.map(item => ({
            productId: item._id,
            quantity: item.quantity
        }));

        try {
            const response = await fetch(`${baseURL}/api/products/fix/cart`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ products: cartData }),
            });

            if (!response.ok) {
                throw new Error('Failed to sync cart with backend');
            }

            const newCartItems = await response.json();

            const updateTx = db.transaction('cart', 'readwrite');
            const updateStore = updateTx.objectStore('cart');

            await updateStore.clear();

            for (const newItem of newCartItems.data) {
                await updateStore.add({
                    _id: newItem._id,
                    quantity: newItem.quantity,
                    name: newItem.name,
                    image: newItem.image,
                    price: newItem.price,
                    stock: newItem.stock,
                });
            }

            setCartItems(newCartItems.data);
        } catch (error) {
            console.error('Error syncing cart with backend:', error.message);
        }
    };

    const calculateTotalPrice = () => {
        return cartItems.reduce((total, item) => {
            return total + item.quantity * item.price;
        }, 0);
    };

    return (
        <CartContext.Provider
            value={{ cartItems, addToCart, incrementQuantity, decrementQuantity, removeFromCart, clearCart, syncCartWithBackend, calculateTotalPrice }}
        >
            {children}
        </CartContext.Provider>
    );

};
