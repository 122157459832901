import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Navbar, Loading } from 'Components';
import { useAuth } from "Context/AuthContext";
import { useCheckStateApi } from './useCheckStateApi';

export const AuthLayout = () => {
    const { isLoggedIn } = useAuth();
    const { data, isLoading } = useCheckStateApi();

    if (isLoggedIn) {
        return <Navigate to="/404" />;
    }
    return (
        isLoading ?
            <Loading /> :
            <>
                {data.isNewUser ?
                    <Navigate to="/signup" /> :
                    <>
                        <Navbar />
                        <Outlet />
                    </>}
            </>
    );
}
