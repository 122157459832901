import { useMutation } from "@tanstack/react-query";
import { useAuth } from "Context/AuthContext";

export const useSignupApi = () => {
    const { signup } = useAuth();

    return useMutation({
        mutationFn: (data) => {
            return signup(data);
        }
    });
};