import { baseURL } from "Components/Common/constance";
import axios from "axios";

const orderEndPoints = "/api/orders/admin/search";
const orderStatusEndPoints = "/api/orders";

export const getOrder = async (authToken, query) => {
    try {
        const response = await axios.get(`${baseURL}${orderEndPoints}`, {
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
            params: query,
        });
        return response.data.data;
    } catch (error) {
        throw new Error(error.response.data.message);
    }
};

export const updateOrderStatus = async (authToken, orderId, data) => {
    try {
        const response = await axios.put(
            `${baseURL}${orderStatusEndPoints}/${orderId}`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};
