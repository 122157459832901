import React from "react";
import { NavLink } from "react-router-dom";
import { useCart } from "Context/CartContext";
import { Logo, Searchbar } from "Components";
import { LuShoppingCart, LuLayoutDashboard, LuLogIn } from "react-icons/lu";
import coffe from "Assets/capputino.png";
import "./style.css";
import { useAuth } from "Context/AuthContext";

export const Navbar = () => {
  const auth = useAuth();
  const token = auth.authorizationToken();

  const { cartItems } = useCart();

  return (
    <div className="navbar_section">
      <div className="flex_r navbar_container">
        <div
          style={{
            marginTop: "10px",
          }}
        >
          <Logo img={coffe} />
        </div>
        <div className="search-form">
          <Searchbar />
        </div>
        <div className="flex_r gap-10 align_items_center">
          {token ? (
            <NavLink
              to="/dashboard/products"
              className={({ isActive }) =>
                ["navbar_icon", isActive ? "active" : ""].join(" ")
              }
            >
              <LuLayoutDashboard />
            </NavLink>
          ) : (
            <NavLink
              to="/dashboard/products"
              className={({ isActive }) =>
                ["navbar_icon", isActive ? "active" : ""].join(" ")
              }
            >
              <LuLogIn />
            </NavLink>
          )}
          <NavLink
            to="/cart"
            className={({ isActive }) =>
              ["navbar_icon", isActive ? "active" : ""].join(" ")
            }
          >
            <LuShoppingCart />
            <span className="cart_items_number">{cartItems.length}</span>
          </NavLink>
        </div>
      </div>
    </div>
  );
};
