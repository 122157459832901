import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, Button } from 'Components';
import './style.css';

export const NewPassword = () => {
    const navigate = useNavigate();
    const [passData, setPassData] = useState('')

    const handleSubmit = (e) => {
        e.preventDefault();
        navigate('/login');
    }
    return (
        <div className='main_container'>
            <div className='flex_c gap-40 auth_page'>
                <div className='flex_c gap-20'>
                    <h1>Create New Password</h1>
                    <p>Your new password must be different from previous used passwords.</p>
                </div>
                <form className='flex_c' onSubmit={handleSubmit}>
                    <Input
                        type="password"
                        label="Password"
                        className="login_input"
                        placeholder="Enter your new password"
                        value={passData}
                        onChange={(e) => {
                            setPassData(e.target.value)
                        }}
                        minLength={8}
                        maxLength={12}
                    />
                    <p className='input_text'>Must be at least 8 characters.</p>
                    <Input
                        type="password"
                        label="Confirm Password"
                        className="login_input"
                        placeholder="Confirm your new password"
                        value={passData}
                        onChange={(e) => {
                            setPassData(e.target.value)
                        }}
                        minLength={8}
                        maxLength={12}
                    />
                    <Button className="margin_top" name={"Reset Password"} type="submit" />
                </form>
            </div>
        </div>
    )
}
