import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Loading } from 'Components';
import { useCheckStateApi } from './useCheckStateApi';

export const FirstUserLayout = () => {
    const { data, isLoading } = useCheckStateApi();
    return (
        <>
            {isLoading ? <Loading /> :
                <>
                    {data.isNewUser ? <Outlet /> : <Navigate to="/login" />}
                </>}
        </>
    )
}
