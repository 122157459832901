import { useInfiniteQuery } from "@tanstack/react-query";
import { getAllCards } from "./allCardsApis";

const allCardsKey = "CARD_KEY";

export const useGetAllCards = (query) => {
    return useInfiniteQuery({
        queryKey: [allCardsKey, query.keywords],
        queryFn: ({ pageParam = 1 }) => {
            return getAllCards({ limit: query.limit, page: pageParam, keywords: query.keywords });
        },
        getNextPageParam: (lastPage) => {
            const currentPage = lastPage.currentPage || 1;
            const totalPages = lastPage.totalPages || 1;
            return currentPage < totalPages ? currentPage + 1 : null;
        },
    });
};
