import { useAuth } from "Context/AuthContext";
import {
    getOrderDetailsById,
    updateOrderStatus,
} from "../OrderDetails/orderDetailsApis";
import { useMutation, useQuery } from "@tanstack/react-query";

const orderDetailsKey = "ORDER_DETAILS_KEY";
const orderDetailsStatusKey = "ORDER_DETAILS_STATUS_KEY";

export const useGetOrderDetailsById = (orderId) => {
    const auth = useAuth();
    const token = auth.authorizationToken();

    return useQuery({
        queryKey: [orderDetailsKey, orderId],
        queryFn: () => {
            return getOrderDetailsById(token, orderId);
        },
        enabled: !!orderId, //to call the api only when the orderId is defined
        cacheTime: 3600000,
        staleTime: 3600000,
        onError: (error) => {
            console.error('An error occurred:', error);
            throw new Error(error);
        },
    });
};

export const useUpdateOrderStatus = (orderId) => {
    const auth = useAuth();
    const token = auth.authorizationToken();

    const getOrderQuery = useGetOrderDetailsById(orderId);

    return useMutation({
        mutationFn: ({ data }) => {
            return updateOrderStatus(token, orderId, data);
        },
        onSuccess: () => {
            getOrderQuery.refetch();
        },
        mutationKey: [orderDetailsStatusKey],
    });
};
