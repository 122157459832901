import React from "react";

const PaginationNumbers = ({ currentPage, totalPages, onSelectPage }) => {
  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 5; // You can adjust this value as needed
    const ellipsis = <span>...</span>;

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(renderPageItem(i));
      }
    } else {
      const leftEllipsis = currentPage > 2;
      const rightEllipsis = currentPage < totalPages - 1;

      if (!leftEllipsis) {
        for (let i = 1; i <= 2; i++) {
          pageNumbers.push(renderPageItem(i));
        }
        pageNumbers.push(ellipsis);
      } else {
        pageNumbers.push(renderPageItem(1));
        pageNumbers.push(renderPageItem(2));
        if (currentPage > 3) {
          pageNumbers.push(ellipsis);
        }
      }

      const startPage = Math.max(3, currentPage - 1);
      const endPage = Math.min(currentPage + 1, totalPages - 1);

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(renderPageItem(i));
      }

      if (!rightEllipsis) {
        pageNumbers.push(ellipsis);
        for (let i = totalPages - 1; i <= totalPages; i++) {
          pageNumbers.push(renderPageItem(i));
        }
      } else {
        if (currentPage < totalPages - 2) {
          pageNumbers.push(ellipsis);
        }
        pageNumbers.push(renderPageItem(totalPages - 1));
        pageNumbers.push(renderPageItem(totalPages));
      }
    }

    return pageNumbers;
  };

  const renderPageItem = (pageNumber) => {
    return (
      <span
        key={pageNumber}
        className={currentPage === pageNumber ? "page_pagination current-page" : "page_pagination"}
        onClick={
          currentPage === pageNumber ? null : () => onSelectPage(pageNumber)
        }
      >
        {pageNumber}
      </span>
    );
  };

  return <div>{renderPageNumbers()}</div>;
};

export default PaginationNumbers;
