import React from "react";

const CircleCheckbox = ({ isChecked, onClick }) => {
  return (
    <div
      style={{
        display: "inline-block",
        width: "24px",
        height: "24px",
        borderRadius: "50%",
        border: "1px solid #807D7E",
        backgroundColor: isChecked ? "transparent" : "transparent",
        cursor: "pointer",
        position: "relative",
      }}
      onClick={onClick}
    >
      {isChecked && (
        <div
          style={{
            width: "15px",
            height: "15px",
            borderRadius: "50%",
            backgroundColor: "#807D7E",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      )}
    </div>
  );
};

export default CircleCheckbox;
