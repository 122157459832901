import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  Loading,
  // Logo
} from "Components";
import { Box, Modal, Typography } from "@mui/material";
import {
  // LuFacebook,
  // LuInstagram,
  // LuMail,
  // LuMapPin,
  // LuPhone,
  // LuTwitter,
  // LuUpload,
  LuX,
} from "react-icons/lu";
import {
  useProfileApis,
  useUpdatePassword,
  useUpdateProfile,
} from "./useProfileApis";
import { transformData } from "Helpers/transformData";
// import { handleFileUpload } from "Helpers/uploadImageApi";
import { useAuth } from "Context/AuthContext";
import { Navigate } from "react-router-dom";
import "./style.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  width: "800px",
  p: 4,
  display: "flex",
  flexDirection: "column",
  gap: "20px",
};

export const Profile = () => {
  const {
    // authorizationToken,
    logout,
  } = useAuth();

  const [modalType, setModalType] = useState(null);
  const [productData, setProductData] = useState({});

  const handleClose = () => setModalType(null);

  const handleChange = (e) => {
    const fieldName = e?.target.name;
    const value = e?.target.value;

    setProductData((prevData) =>
      transformData({
        ...prevData,
        [fieldName]: value,
      })
    );
  };

  const {
    data: getProfileData,
    status: getProfileStatus,
    error: getProfileError,
  } = useProfileApis();

  const { mutateAsync: updateProfile } = useUpdateProfile();
  const { mutateAsync: updatePassword } = useUpdatePassword();

  const updateProfileById = async () => {
    const data = transformData(productData);
    try {
      const response = await updateProfile(data);
      if (!response.success) {
        alert(response.message);
        return response;
      }
      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const updateAdminPassword = async () => {
    const data = transformData(productData);
    try {
      const response = await updatePassword(data);
      if (!response.success) {
        alert(response.message);
        return response;
      }
      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  };
  // const handleImageUpload = async (e) => {
  //     try {
  //         let response = await handleFileUpload(authorizationToken(), e.target.files[0]);
  //         setProductData((prevData) => transformData({
  //             ...prevData,
  //             "businessInfo.logo": response.data.image,
  //         }));
  //     } catch (err) {
  //         console.err(err)
  //     }
  // }

  // const handleDeleteLogo = () => {}

  useEffect(() => {
    setProductData(getProfileData);
  }, [getProfileData]);

  if (getProfileStatus === "pending") {
    return <Loading />;
  }

  if (getProfileStatus === "error") {
    if (
      getProfileError?.message ===
        "Your sessions token has expired !! Login again" ||
      getProfileError?.message === "Unauthorized to access" ||
      getProfileError?.message === "Invalid Token" ||
      getProfileError?.message === "The token owner no longer exist"
    ) {
      alert(getProfileError?.message);
      logout();
      return <Navigate to={"/login"} />;
    }
    if (getProfileError?.message === "Order not found") {
      return <Navigate to={"/404"} />;
    }
    return <div>{getProfileError?.message}</div>;
  }

  if (getProfileStatus === "success") {
    return (
      <div className="dashboard_container">
        <h1 className="dashboard_title">My Profile</h1>
        <div className=" flex_c gap-30 profile_details">
          <h1>Profile Details</h1>
          <div className="flex_r space_between profile_name">
            <div className="flex_c gap-10">
              <p className="profile-your-headtitle">Your Name</p>
              <p className="profile_your_subtitle">{getProfileData.name}</p>
            </div>
            <div className="profile_name_change">
              <p onClick={() => setModalType("name")}>Change</p>
              <Modal
                open={modalType === "name"}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={{ ...style, width: "400px" }}>
                  <div className="flex_r space_between">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      Set Name
                    </Typography>
                    <LuX onClick={handleClose} />
                  </div>
                  <Input
                    type="text"
                    label={"Your Name"}
                    id="yourName"
                    placeholder="Your Name"
                    required
                    className="checkout_input"
                    name="name"
                    value={productData?.name}
                    onChange={handleChange}
                  />
                  <div className="flex_r flex_end">
                    <Button
                      name={"Cancel"}
                      className="profile_modal_btn"
                      onClick={handleClose}
                    />
                    <Button
                      name={"Save"}
                      className="business_profile_btn"
                      onClick={() => {
                        updateProfileById();
                        handleClose();
                      }}
                    />
                  </div>
                </Box>
              </Modal>
            </div>
          </div>

          {/* <div className="flex_r space_between profile_name">
            <div className="flex_c gap-10">
              <p className="profile-your-headtitle">Email Address</p>
              <p className="profile_your_subtitle"> {getProfileData.email}</p>
            </div>
            <div className="profile_name_change">
              <p onClick={() => setModalType("email")}>Change</p>
              <Modal
                open={modalType === "email"}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={{ ...style, width: "400px" }}>
                  <div className="flex_r space_between">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      Email
                    </Typography>
                    <LuX onClick={handleClose} />
                  </div>
                  <Input
                    type="email"
                    label={"Email Address"}
                    id="email"
                    name="email"
                    required
                    className="checkout_input"
                    value={productData?.email}
                  />
                  <div className="flex_r flex_end">
                    <Button
                      name={"Cancel"}
                      className="profile_modal_btn"
                      onClick={handleClose}
                    />
                    <Button name={"Save"} />
                  </div>
                </Box>
              </Modal>
            </div>
          </div> */}

          <div className="flex_r space_between profile_name">
            <div className="flex_c gap-10">
              <p className="profile-your-headtitle">Phone Number</p>
              <p className="profile_your_subtitle">{getProfileData.phone}</p>
            </div>
            <div className="profile_name_change">
              <p onClick={() => setModalType("phone-number")}>Change</p>
              <Modal
                open={modalType === "phone-number"}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={{ ...style, width: "400px" }}>
                  <div className="flex_r space_between">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      Phone Number
                    </Typography>
                    <LuX onClick={handleClose} />
                  </div>
                  <Input
                    type="text"
                    label={"Phone Number"}
                    id="phone-number"
                    required
                    name="phone"
                    className="checkout_input"
                    value={productData?.phone}
                    onChange={handleChange}
                  />
                  <div className="flex_r flex_end">
                    <Button
                      name={"Cancel"}
                      className="profile_modal_btn"
                      onClick={handleClose}
                    />
                    <Button
                      name={"Save"}
                      className="business_profile_btn"
                      onClick={() => {
                        updateProfileById();
                        handleClose();
                      }}
                    />
                  </div>
                </Box>
              </Modal>
            </div>
          </div>

          <div className="flex_r space_between profile_name">
            <div className="flex_c gap-10">
              <p className="profile-your-headtitle">Password</p>
              <p className="profile_your_subtitle">************</p>
            </div>
            <div className="profile_name_change">
              <p onClick={() => setModalType("password")}>Change</p>
              <Modal
                open={modalType === "password"}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={{ ...style, width: "400px" }}>
                  <div className="flex_r space_between">
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      Password
                    </Typography>
                    <LuX onClick={handleClose} />
                  </div>
                  <Input
                    type="password"
                    label={"Old Password"}
                    id="password"
                    required
                    className="checkout_input"
                    name="oldPassword"
                    value={productData?.password}
                    onChange={handleChange}
                  />
                  <Input
                    type="password"
                    label={"New Password"}
                    id="password"
                    required
                    className="checkout_input"
                    name="newPassword"
                    value={productData?.password}
                    onChange={handleChange}
                  />
                  <div className="flex_r flex_end">
                    <Button
                      name={"Cancel"}
                      className="profile_modal_btn"
                      onClick={handleClose}
                    />
                    <Button
                      name={"Save"}
                      className="business_profile_btn"
                      onClick={() => {
                        updateAdminPassword();
                        handleClose();
                      }}
                    />
                  </div>
                </Box>
              </Modal>
            </div>
          </div>

          {/* <div className="flex_c gap-30 profile_business_details">
                              <div className="flex_r space_between profile_details">
                                  <h1>Business Details</h1>
                                  <Button
                                      name={"Edit"}
                                      className="business_profile_btn"
                                      onClick={() => setModalType("edite-name")}
                                  />
                                  <Modal
                                      open={modalType === "edite-name"}
                                      onClose={handleClose}
                                      aria-labelledby="modal-modal-title"
                                      aria-describedby="modal-modal-description"
                                      style={{
                                          width: "1200",
                                      }}
                                  >
                                      <Box sx={style}>
                                          <div className="flex_r space_between">
                                              <Typography
                                                  id="modal-modal-title"
                                                  variant="h6"
                                                  component="h2"
                                              >
                                                  Edit Name
                                              </Typography>
                                              <LuX onClick={handleClose} />
                                          </div>
                                          <div className="business_modal_business_details">
                                              <div className="flex_c">
                                                  <label className="label_inputs">
                                                      <span className="label">Comapny Logo</span>
                                                      <div className="flex_r space_between image_input align_items_center">
                                                          <div className="flex_r gap-10 align_items_center">
                                                              <img src={productData?.businessInfo.logo} alt={getProfileData.businessInfo.name} />
                                                              <p>Upload Logo</p>
                                                          </div>
                                                          <input
                                                              id="companyLogo"
                                                              type="file"
                                                              hidden
                                                              onChange={handleImageUpload}
                                                          />
                                                          <LuUpload />
                                                      </div>
                                                  </label>
                                              </div>
                                              <Input
                                                  type="text"
                                                  label={"Comapny Name*"}
                                                  id="businessInfo.name"
                                                  required
                                                  className="checkout_input"
                                                  name="businessInfo.name"
                                                  value={productData?.businessInfo.name}
                                                  onChange={handleChange}
                                              />
                                              <div className="flex_c gap-10">
                                                  <label className="label">Company Description</label>
                                                  <textarea
                                                      className="custom_textarea checkout_input"
                                                      placeholder="Product Description"
                                                      name="businessInfo.description"
                                                      value={productData?.businessInfo.description}
                                                      onChange={handleChange}
                                                  ></textarea>
                                              </div>
                                          </div>
                                          <div className="flex_r flex_end">
                                              <Button
                                                  name={"Cancel"}
                                                  className="profile_modal_btn"
                                                  onClick={handleClose}
                                              />
                                              <Button
                                                  name={"Save"}
                                                  className="business_profile_btn"
                                                  onClick={() => {
                                                      updateProfileById();
                                                      handleClose();
                                                  }}
                                              />
                                          </div>
                                      </Box>
                                  </Modal>
                              </div>
  
                              <div className="flex_r gap-40 profile_company_details">
                                  <div className="flex_c gap-20 profile_company_section">
                                      <p className="profile-your-headtitle">Company Logo</p>
                                      <Logo
                                          img={getProfileData.businessInfo.logo}
                                          className="profile_company_logo"
                                      />
                                  </div>
                                  <div className="flex_c gap-20 profile_details_section">
                                      <div className="flex_c gap-10 ">
                                          <p className="profile-your-headtitle">Company Name</p>
                                          <p className="profile_your_subtitle">
                                              {getProfileData.businessInfo.name}
                                          </p>
                                      </div>
                                      <div className="flex_c gap-10 ">
                                          <p className="profile-your-headtitle">
                                              Company Description
                                          </p>
                                          <p className="profile_your_subtitle">
                                              {getProfileData.businessInfo.description}
                                          </p>
                                      </div>
                                  </div>
                              </div>
                          </div>
  
                          <div className="flex_c gap-30 profile_contact_details">
                              <div className="flex_r space_between profile_details">
                                  <h1>Contact Details</h1>
                                  <Button
                                      name={"Edit"}
                                      className={"business_profile_btn"}
                                      onClick={() => setModalType("edite-contact")}
                                  />
                                  <Modal
                                      open={modalType === "edite-contact"}
                                      onClose={handleClose}
                                      aria-labelledby="modal-modal-title"
                                      aria-describedby="modal-modal-description"
                                      style={{
                                          width: "1200",
                                      }}
                                  >
                                      <Box sx={style}>
                                          <div className="flex_r space_between">
                                              <Typography
                                                  id="modal-modal-title"
                                                  variant="h6"
                                                  component="h2"
                                              >
                                                  Edit Contacts
                                              </Typography>
                                              <LuX onClick={handleClose} />
                                          </div>
                                          <div className="business_modal_business_details">
                                              <Input
                                                  type="text"
                                                  label={"Location"}
                                                  required
                                                  className="checkout_input"
                                                  style={{
                                                      width: "90%",
                                                  }}
                                                  name="businessInfo.location"
                                                  value={productData?.businessInfo.location}
                                                  onChange={handleChange}
                                              />
                                              <Input
                                                  type="text"
                                                  label={"Email"}
                                                  required
                                                  className="checkout_input"
                                                  style={{
                                                      width: "90%",
                                                  }}
                                                  name="businessInfo.email"
                                                  value={productData?.businessInfo.email}
                                                  onChange={handleChange}
                                              />
                                              <Input
                                                  type="text"
                                                  label={"Phone"}
                                                  required
                                                  className="checkout_input"
                                                  style={{
                                                      width: "90%",
                                                  }}
                                                  name="businessInfo.phone"
                                                  value={productData?.businessInfo.phone}
                                                  onChange={handleChange}
                                              />
                                              <Input
                                                  type="text"
                                                  label={"Instagram"}
                                                  required
                                                  className="checkout_input"
                                                  style={{
                                                      width: "90%",
                                                  }}
                                                  name="businessInfo.instagram"
                                                  value={productData?.businessInfo.instagram}
                                                  onChange={handleChange}
                                              />
                                              <Input
                                                  type="text"
                                                  label={"Facebook"}
                                                  required
                                                  className="checkout_input"
                                                  style={{
                                                      width: "90%",
                                                  }}
                                                  name="businessInfo.facebook"
                                                  value={productData?.businessInfo.facebook}
                                                  onChange={handleChange}
                                              />
                                              <Input
                                                  type="text"
                                                  label={"Twitter"}
                                                  required
                                                  className="checkout_input"
                                                  style={{
                                                      width: "90%",
                                                  }}
                                                  name="businessInfo.twitter"
                                                  value={productData?.businessInfo.twitter}
                                                  onChange={handleChange}
                                              />
                                          </div>
                                          <div className="flex_r flex_end">
                                              <Button
                                                  name={"Cancel"}
                                                  className="profile_modal_btn"
                                                  onClick={handleClose}
                                              />
                                              <Button
                                                  name={"Save"}
                                                  className="business_profile_btn"
                                                  onClick={() => {
                                                      updateProfileById();
                                                      handleClose();
                                                  }}
                                              />
                                          </div>
                                      </Box>
                                  </Modal>
                              </div>
                              <div className="flex_r gap-40 contact_details_wrapping">
                                  <div className="flex_c gap-10 contat_details_size">
                                      <LuMapPin className="profile_contact_icons" />
                                      <p className="profile_your_subtitle">
                                          {getProfileData.businessInfo.location || "~"}
                                      </p>
                                  </div>
  
                                  <div className="flex_c gap-10 contat_details_size">
                                      <LuInstagram className="profile_contact_icons" />
                                      <p className="profile_your_subtitle">
                                          {getProfileData.businessInfo.instagram || "~"}
                                      </p>
                                  </div>
  
                                  <div className="flex_c gap-10 contat_details_size">
                                      <LuMail className="profile_contact_icons" />
                                      <p className="profile_your_subtitle">
                                          {getProfileData.businessInfo.email || "~"}
                                      </p>
                                  </div>
  
                                  <div className="flex_c gap-10 contat_details_size">
                                      <LuFacebook className="profile_contact_icons" />
                                      <p className="profile_your_subtitle">
                                          {getProfileData.businessInfo.facebook || "~"}
                                      </p>
                                  </div>
  
                                  <div className="flex_c gap-10 contat_details_size">
                                      <LuPhone className="profile_contact_icons" />
                                      <p className="profile_your_subtitle">
                                          {getProfileData.businessInfo.phone || "~"}
                                      </p>
                                  </div>
  
                                  <div className="flex_c gap-10 contat_details_size">
                                      <LuTwitter className="profile_contact_icons" />
                                      <p className="profile_your_subtitle">
                                          {getProfileData.businessInfo.twitter || "~"}
                                      </p>
                                  </div>
                              </div>
                          </div> */}
        </div>
      </div>
    );
  }
};
