import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCart } from "Context/CartContext";
import { Button } from "Components/Common/button";
import { LuMinus, LuPlus, LuTrash2 } from "react-icons/lu";
import { Table } from "Components";
import { EmptyCart } from "Components/EmptyCart";
import "./style.css";

export const Cart = () => {
  const navigate = useNavigate();
  const { cartItems, removeFromCart, incrementQuantity, decrementQuantity, syncCartWithBackend, calculateTotalPrice } = useCart();
  let shippingFees = 5;

  const handleNavigateToCheckout = () => {
    navigate("/checkout");
  };

  useEffect(() => {
    syncCartWithBackend()
  }, []);

  // const goBack = () => {
  //   navigate(-1);
  // };
  if (cartItems.length === 0) {
    return <EmptyCart />;
  }
  return (
    //   <div
    //   className="flex_r align_items_center"
    //   onClick={goBack}
    //   style={{ cursor: "pointer" }}
    // >
    //   <LuChevronLeft style={{ fontSize: "25px" }} />
    //   <p className="dashboard_title">Back</p>
    // </div>
    <>
      <div className="main_container gap-20 cart_container">
        <div className="cart_table">
          <Table heading={["PRODUCT DETAILS", "QUANTITY", "TOTAL", " "]}>
            {cartItems?.map((item) => (
              <tr key={item._id}>
                <td>
                  <div className="flex_r gap-20 align_items_center">
                    <img
                      src={item.image}
                      className="dashboard_table_row_img"
                      alt={item.name}
                      onClick={() => navigate(`/products/${item._id}`)}
                    />
                    {item.name}
                  </div>
                </td>
                <td>
                  <div className="flex_r">
                    <div className="flex_r gap-20 align_items_center cart_quantity_icons">
                      <LuMinus
                        onClick={() => decrementQuantity(item._id)}
                        className="cart_icon"
                      />
                      {item.quantity}
                      <LuPlus
                        onClick={() => incrementQuantity(item._id)}
                        className="cart_icon"
                      />
                    </div>
                  </div>
                </td>
                <td>{item.price * item.quantity}</td>
                <td>
                  <LuTrash2
                    style={{ color: "#EE1D52", fontSize: "20px" }}
                    onClick={() => removeFromCart(item._id)}
                  />
                </td>
              </tr>
            ))}
          </Table>
        </div>

        <div className="flex_c cartpage_proccess_checkout_subpage">
          <div className=" flex_c gapping_Cart_page">
            <div className="flex_r_spacebetween cart_page_Sub_Total">
              <p>Sub Total</p>
              <p>$ {calculateTotalPrice()}</p>
            </div>
            <div className="flex_r_spacebetween cart_page_Shipping">
              <p>Shipping</p>
              <p>$ {shippingFees}</p>
            </div>
          </div>
          <div className="flex_r_spacebetween cart_page_Total">
            <p>Total</p>
            <p>$ {calculateTotalPrice() + shippingFees}</p>
          </div>
          <Button
            name={"Proceed To Checkout"}
            className="cart_button"
            onClick={handleNavigateToCheckout}
          />
        </div>
      </div>
      {/* // </div> */}
    </>
  );
};
