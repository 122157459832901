import { BrowserRouter, Route, Routes } from "react-router-dom";
import { UserLayout, AuthLayout, DashboardLayout, FirstUserLayout } from "./Layout";
import {
    Cart,
    CheckEmail,
    Checkout,
    CodeVerification,
    Home,
    Login,
    NewPassword,
    ResetPassword,
    SignUp,
    ConfirmedOrder,
    FourOFour,
    SingleCard,
    DashboardProducts,
    DashboardOrders,
    DashboardProductDetails,
    DashboardOrderDetails,
    Profile,
    Onboarding,
} from "./Pages";
import { AuthProvider } from "Context/AuthContext";
import { CartProvider } from "Context/CartContext";
import { SearchProvider } from "Context/SearchContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./App.css";

export const queryClient = new QueryClient();
export const App = () => {
    return (
        <QueryClientProvider client={queryClient}>
            <AuthProvider>
                <SearchProvider>
                    <CartProvider>
                        <BrowserRouter>
                            <Routes>
                                <Route path="/" element={<AuthLayout />}>
                                    <Route path="login" element={<Login />} />
                                    <Route path="check-email" element={<CheckEmail />} />
                                    <Route path="reset-password" element={<ResetPassword />} />
                                    <Route path="new-password" element={<NewPassword />} />
                                </Route>

                                <Route path="/" element={<FirstUserLayout />}>
                                    <Route path="signup" element={<SignUp />} />
                                    <Route path="verify-code" element={<CodeVerification />} />
                                </Route>

                                <Route path="/" element={<UserLayout />}>
                                    <Route index element={<Home />} />
                                    <Route path="products/:productId" element={<SingleCard />} />
                                    <Route path="cart" element={<Cart />} />
                                    <Route path="checkout" element={<Checkout />} />
                                    <Route path="confirmed-order" element={<ConfirmedOrder />} />
                                    <Route path="404" element={<FourOFour />} />
                                </Route>
                                <Route path="/dashboard" element={<DashboardLayout />}>
                                    <Route path="products" element={<DashboardProducts />} />
                                    <Route path="products/:productId" element={<DashboardProductDetails />} />

                                    <Route path="orders" element={<DashboardOrders />} />
                                    <Route path="orders/:orderId" element={<DashboardOrderDetails />} />

                                    <Route path="profile" element={<Profile />} />
                                </Route>
                                <Route path="onboarding" element={<Onboarding />} />
                                <Route path="*" element={<FourOFour />} />
                            </Routes>
                        </BrowserRouter>
                    </CartProvider>
                </SearchProvider>
            </AuthProvider>
        </QueryClientProvider>
    );
};
