import { createContext, useContext, useState } from "react";
import axios from "axios";
import { baseURL } from "Components/Common/constance";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem("token"));

    const checkState = async () => {
        try {
            let response = await axios.get(`${baseURL}/api/auth/check/state`);
            return response.data.data.isNewUser;
        } catch (err) {
            console.error(err);
            return err;
        }
    };

    const authorizationToken = () => {
        var token = localStorage.getItem("token");
        if (token) {
            return token;
        } else return null;
    };

    const login = async (token) => {
        localStorage.setItem("token", token);
        setIsLoggedIn(true);
    };

    const signup = async (data) => {
        try {
            localStorage.setItem("email", data.email);
            let response = await axios.post(`${baseURL}/api/auth/register`, data);
            return response.data;
        } catch (err) {
            console.error(err);
            return err.response.data;
        }
    };

    const verifyOtp = async (otp) => {
        try {
            let email = localStorage.getItem("email");
            let data = { otp, email }
            let response = await axios.put(`${baseURL}/api/auth/verify/otp`, data);
            localStorage.removeItem("email");
            localStorage.setItem("token", response.data.data.token);
            setIsLoggedIn(true);
            return response.data;
        } catch (err) {
            console.error(err);
            return err.response.data;
        }
    };

    const logout = () => {
        setUser(null);
        localStorage.removeItem("token");
        setIsLoggedIn(false);
    };

    return (
        <AuthContext.Provider
            value={{ user, isLoggedIn, login, logout, signup, verifyOtp, authorizationToken, checkState }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
