import { useMutation } from "@tanstack/react-query";
import { useAuth } from "Context/AuthContext";
import { updateOnboarding } from "./onboardingApi";

export const useOnboardingApi = () => {
    const auth = useAuth();
    const token = auth.authorizationToken();

    return useMutation({
        mutationKey: "onboarding-key",
        mutationFn: (data) => {
            return updateOnboarding(token, data);
        }
    });
};