import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "Components";
import confirmedOrder from "Assets/success.png";
import "./style.css";

export const ConfirmedOrder = () => {
    const navigate = useNavigate();
    const navigateToHome = () => {
        navigate("/");
    };
    return (
        <div className="main_container">
            <div className="flex_c gap-20 align_items_center confirmed_order">
                <img src={confirmedOrder} alt="confirmed order" />
                <p>
                    Your Order is
                    <br /> Confirmed
                </p>
                <Button name={"Continue Shopping"} onClick={navigateToHome} />
            </div>
        </div>
    );
};
