import axios from "axios";
import { baseURL } from "Components/Common/constance";

const createOrderEndPoints = "/api/orders";

export const postCheckout = async (data) => {
    try {
        const response = await axios.post(`${baseURL}${createOrderEndPoints}`, data);
        return response.data;
    } catch (error) {
        console.error(error)
        return error.response.data;
    }
};
