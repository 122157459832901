import React from "react";
import { Logo } from "Components";
import { LuInstagram, LuFacebook } from "react-icons/lu";
import coffe from "Assets/capputino.png";
import "./style.css";

export const Footer = () => {
  return (
    <div className="flex_c footer_container">
      <div className="flex_r footer_sub_container">
        <div className="flex_c gap-20 footer_about">
          <div className="flex_r gap-20 footer_title">
            <Logo img={coffe} alt={"brand logo"} />
            <h1>Website Name</h1>
          </div>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
            con Excepteur sint occaecat cupidatat non proident,
          </p>
        </div>

        <div className="flex_c gap-20 footer_info">
          <div className="footer_title">
            <h1> More Info</h1>
          </div>
          <div className="flex_c footer_list">
            <p>lndsakjn kjdnas </p>
            <p>lndsakjn kjdnas </p>
            <p>lndsakjn kjdnas </p>
          </div>
        </div>

        <div className="flex_c gap-20 footer_contact">
          <div className="footer_title">
            <h1>Contact Us</h1>
          </div>
          <ul className="flex_c gap-10 footer_list">
            <li>+961 000 000</li>
            <li>example@gmail.com</li>
            <li>North Lebanon, Al Mina</li>
            <div className="flex_r gap-10">
              <LuFacebook className="social_icon" />
              <LuInstagram className="social_icon" />
            </div>
          </ul>
        </div>
      </div>

      <div className="flex_r footer_copy_right">
        <p>Copyright ©Moe Matar All rights reserved '2024'.</p>
      </div>
    </div>
  );
};
