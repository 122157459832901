// Hook
import { useMutation } from "@tanstack/react-query";
import { postCheckout } from "./checkoutApis";

export const usePostCheckout = () => {
    return useMutation({
        mutationFn: (data) => {
            return postCheckout(data);
        }
    });
};
