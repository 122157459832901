export const switchSortedByValue = (value) => {
    switch (value) {
        case "newest":
            return "Newest Item";
        case "mostPopular":
            return "Most Popular";
        case "priceHTL":
            return "Price (hight to low)";
        case "priceLTH":
            return "Price (low to high)";
        case "alphabetical":
            return "Alphabetical";
        case "all":
            return "All Times";
        case "today":
            return "Today";
        case "lastWeek":
            return "Last Week";
        case "lastMonth":
            return "Last Month";
        case "last6Months":
            return "Last 6 months";
        case "lastYear":
            return "Last Year";
        default:
            break;
    }
}