import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "Components";
import fourofour from "Assets/404.png";
import "./style.css";

export const FourOFour = () => {
    const navigate = useNavigate();
    const navigateToHome = () => {
        navigate("/");
    };
    return (
        <div className="main_container">
            <div className="flex_c style_404">
                <img src={fourofour} alt="404" />
                <h1>Oops! Page not found</h1>
                <p>
                    The page you are looking for might have been removed or temporarily
                    unavailable.
                </p>
                <Button name={"Back to HomePage"} onClick={navigateToHome} />
            </div>
        </div>
    );
};
