import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { NavbarDashboard, SideBar } from "Components";
import { useAuth } from "Context/AuthContext";

export const DashboardLayout = () => {
  const { isLoggedIn } = useAuth();

  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <NavbarDashboard />
      <SideBar />
      <div className="dashboard_page">
        <Outlet />
      </div>
    </>
  );
};
