export const transformData = (obj) => {
    const formattedData = {};

    for (const key in obj) {
        if (key.includes('.')) {
            const keys = key.split('.');
            const [currentKey, ...remainingKeys] = keys;

            if (!formattedData[currentKey]) {
                formattedData[currentKey] = {};
            }

            formattedData[currentKey] = {
                ...formattedData[currentKey],
                ...transformData({ [remainingKeys.join('.')]: obj[key] })
            };
        } else {
            formattedData[key] = obj[key];
        }
    }

    return formattedData;
}