import { baseURL } from "Components/Common/constance";
import axios from "axios";

const oderDetailsEndPoints = "/api/orders/";
const orderDetailsStatusEndPoints = "/api/orders/";

export const getOrderDetailsById = async (authToken, orderId) => {
    try {
        const response = await axios.get(
            `${baseURL}${oderDetailsEndPoints}${orderId}`,
            {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            }
        );
        return response.data.data;
    } catch (error) {
        throw new Error(error.response.data.message);
    }
};

export const updateOrderStatus = async (authToken, orderId, data) => {
    try {
        const response = await axios.put(
            `${baseURL}${orderDetailsStatusEndPoints}${orderId}`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};
