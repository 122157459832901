import { useMutation, useQuery } from "@tanstack/react-query";
import { getProductDetailsById, updateProduct } from "./ProductDetailsApi";
import { useAuth } from "Context/AuthContext";
import { queryClient } from "App";

const productDetails = "PRODUCT_DETAILS_KEY";
const productTableKey = "PRODUCT_TABLE_KEY";

export const useGetProductDetailsById = (productId) => {
  const auth = useAuth();
  const token = auth.authorizationToken();

  return useQuery({
    queryFn: () => {
      return getProductDetailsById(token, productId);
    },
    queryKey: [productDetails, productId],
    enabled: !!productId,
    cacheTime: 3600000,
    staleTime: 3600000,
    onError: (error) => {
      console.error('An error occurred:', error);
      throw new Error(error);
    },
  });
};

export const useUpdateProduct = (productId) => {
  const auth = useAuth();
  const token = auth.authorizationToken();

  return useMutation({
    mutationFn: ({ data }) => {
      return updateProduct(token, productId, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(productTableKey);
    },
  });
};
