import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, Button, Loading } from 'Components';
import { color } from 'theme';
import { useSignupApi } from './useSignupApi';
import './style.css';

export const SignUp = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false)
    const [signupData, setSignupData] = useState({});
    // const [checkPrivacy, setCheckPrivacy] = useState(false);

    const { mutateAsync: signup } = useSignupApi();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const response = await signup(signupData);
            if (!response.success) {
                alert(response.message);
            }
            setIsLoading(false);
            navigate("/verify-code")
            console.log(response);
            return response;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    const handleChange = (e) => {
        const fieldName = e?.target.name;
        const value = e?.target.value;
        setSignupData((prevData) => ({
            ...prevData,
            [fieldName]: value,
        }));
    };

    return (
        <div className='main_container'>
            {isLoading ? <Loading /> : (
                <div className='flex_c gap-40 auth_page'>
                    <div className='flex_c gap-20'>
                        <h1 style={{ color: color.titleColor }}>
                            Sign Up
                        </h1>
                        <p style={{ color: color.textColor }}>Sign up for free to access to in any of our products </p>
                    </div>

                    <form className='flex_c' onSubmit={handleSubmit}>
                        <Input
                            className="login_input"
                            label={"Name"}
                            type={"text"}
                            name="name"
                            placeholder="Your Name"
                            required
                            onChange={handleChange}
                            value={signupData.name || ""}
                        />
                        <Input
                            className="login_input"
                            label={"Email Address"}
                            type={"email"}
                            name="email"
                            placeholder="example@gmail.com"
                            required
                            onChange={handleChange}
                            value={signupData.email || ""}
                        />
                        <Input
                            className="login_input"
                            label="Password"
                            type="password"
                            name="password"
                            placeholder="***********"
                            required
                            onChange={handleChange}
                            value={signupData.password || ""}
                            minLength={8}
                            maxLength={12}
                        />
                        <p className='input_text'>Must be at least 8 characters.</p>
                        {/* <Input type={"checkbox"} checked={checkPrivacy && "checked"} onChange={(e) => setCheckPrivacy(!checkPrivacy)} label={"Agree to our Terms of use and Privacy Policy"} /> */}
                        {/* {error && <div style={{ color: 'red' }}>{error}</div>} */}
                        <Button name={"Sign Up"} type="submit" />
                    </form>
                </div>
            )}
        </div>
    )
}