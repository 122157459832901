import { useQuery } from "@tanstack/react-query";
import { baseURL } from "Components/Common/constance";
import axios from "axios";

const checkStateEndPoint = "/api/auth/check/state";

export const useCheckStateApi = () => {
    return useQuery({
        queryKey: ["auth_key"],
        queryFn: async () => {
            try {
                const response = await axios.get(`${baseURL}${checkStateEndPoint}`);
                return response.data.data;
            } catch (error) {
                return error.response.data;
            }
        },
        cacheTime: 3600000,
        staleTime: 3600000,
        enabled: true,
    });
};