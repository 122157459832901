import React from "react";
import { Logo } from "Components/Logo";
import { LuGlobe, LuUser } from "react-icons/lu";
import capputino from "Assets/capputino.png";
import { NavLink } from "react-router-dom";
import "./style.css";

export const NavbarDashboard = () => {
  return (
    <div className="navbar_section dashboard_navbar_section">
      <div className="flex_r space_between dashboard_navbar">
        <Logo img={capputino} />
        <div className="flex_r gap-10 align_items_center">
          <NavLink
            to="/"
            className={({ isActive }) =>
              ["navbar_icon", isActive ? "active" : ""].join(" ")
            }
          >
            <LuGlobe />
          </NavLink>
          <NavLink
            to="/dashboard/profile"
            className={({ isActive }) =>
              ["navbar_icon", isActive ? "active" : ""].join(" ")
            }
          >
            <LuUser />
          </NavLink>
        </div>
      </div>
    </div>
  );
};
