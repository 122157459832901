import { baseURL } from "Components/Common/constance";
import axios from "axios";

const singleCardsEndPoint = "/api/products/";

export const getSingleCardById = async (productId) => {
    try {
        const response = await axios.get(
            `${baseURL}${singleCardsEndPoint}${productId}`
        );
        return response.data.data;
    } catch (error) {
        return error.response.data;
    }
};
