import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Input, Loading } from "Components";
import { Box, Button, Modal, Typography, Menu, MenuItem } from "@mui/material";
import {
    LuX,
    LuChevronLeft,
    LuChevronDown,
    LuImagePlus,
    LuUpload,
} from "react-icons/lu";
import {
    useGetProductDetailsById,
    useUpdateProduct,
} from "./useProductDetailsApi";
import { switchStatusColor } from "Helpers/switchStatusColor";
import { useDeleteProduct } from "../Products/useProductAnalyticsApis";
import { transformData } from "Helpers/transformData";
import { useAuth } from "Context/AuthContext";
import { handleFileUpload } from "Helpers/uploadImageApi";
import "./style.css";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

export const DashboardProductDetails = () => {
    const { authorizationToken, logout } = useAuth();
    const navigate = useNavigate();
    let { productId } = useParams();
    const [openModal, setOpenModal] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    const {
        data: getData,
        status: productDetailsStatus,
        error: productDetailsError
    } = useGetProductDetailsById(productId);

    const [productData, setProductData] = useState({});
    const {
        mutateAsync: updateProduct,
        status: updateStatus
    } = useUpdateProduct(productId);

    useEffect(() => {
        setProductData(getData);
    }, [getData]);

    const updateStatusById = async (newStatus) => {
        try {
            const response = await updateProduct({ data: { status: newStatus } });
            if (!response.success) {
                alert(response.message);
            }
            return response;
        } catch (error) {
            console.error(error);
            return null;
        }
    };

    const updateProductDetails = async () => {
        const data = transformData(productData);
        try {
            const response = await updateProduct({ data });
            if (!response.success) {
                alert(response.message);
                return response;
            }
            return response;
        } catch (error) {
            console.error(error);
            return null;
        }
    };

    const {
        mutateAsync: deleteProduct,
        status: deleteStatus
    } = useDeleteProduct();

    const handleDelete = async (id) => {
        try {
            const response = await deleteProduct({ productId: id });
            if (!response.success) {
                alert(response.message);
                return response;
            }
            navigate("/dashboard/products");
            return response;
        } catch (error) {
            console.error(error);
            return null;
        }
    };

    const goBack = () => {
        navigate(-1);
    };

    const handleChange = (e) => {
        const fieldName = e?.target.name;
        let value = e?.target.value;
        if (fieldName === "stock" || fieldName === "price") {
            value = Number(value);
        }
        setProductData((prevData) => ({
            ...prevData,
            [fieldName]: value,
        }));
    };

    const handleChangeImage = async (e) => {
        try {
            let response = await handleFileUpload(
                authorizationToken(),
                e.target.files[0]
            );
            setProductData((prevData) => ({
                ...prevData,
                image: response.data.image,
            }));
        } catch (err) {
            console.err(err);
        }
    };

    if (productDetailsStatus === "pending") {
        return (<Loading />)
    }

    if (productDetailsStatus === "error") {
        if (
            productDetailsError?.message === "Your sessions token has expired !! Login again" ||
            productDetailsError?.message === "Unauthorized to access" ||
            productDetailsError?.message === "Invalid Token" ||
            productDetailsError?.message === "The token owner no longer exist"
        ) {
            alert(productDetailsError?.message);
            logout();
            return (<Navigate to={"/login"} />);
        }
        if (productDetailsError?.message === "Product not found") {
            return (<Navigate to={"/404"} />);
        }
        return (<div>{productDetailsError?.message}</div>);
    }

    if (productDetailsStatus === "success") {
        return (
            <div className="dashboard_container">
                <div className="flex_c gap-40">
                    <div className="flex_r dashboard_product_title">
                        <div className="flex_r space_between w-100">
                            <div className="flex_r align_items_center gap-10">
                                <LuChevronLeft
                                    className="icon_style"
                                    style={{ fontSize: "25px" }}
                                    onClick={goBack}
                                />
                                <h1 className="dashboard_title">Product Details</h1>
                            </div>
                            <div>
                                <Button
                                    id="product-status-button"
                                    aria-controls={"product-status-menu"}
                                    aria-haspopup="true"
                                    // aria-expanded={open1 ? "true" : undefined}
                                    onClick={getData.status === "deleted" ? () => { } : (e) => setMenuOpen(true)}
                                    endIcon={getData.status !== "deleted" && <LuChevronDown />}
                                    style={{
                                        fontSize: "14px",
                                        color: switchStatusColor(getData.status),
                                        border: `2px solid ${switchStatusColor(getData.status)}`,
                                    }}
                                >
                                    {getData.status}
                                </Button>
                                <Menu
                                    id="product-status-menu"
                                    anchorEl={document.getElementById("product-status-button")}
                                    open={menuOpen}
                                    onClose={() => setMenuOpen(false)}
                                    MenuListProps={{
                                        "aria-labelledby": "product-status-button",
                                    }}
                                >
                                    <MenuItem
                                        onClick={() => {
                                            updateStatusById("published");
                                            setMenuOpen(false);
                                        }}
                                        style={{ color: "green" }}
                                    >
                                        Published
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => {
                                            updateStatusById("draft");
                                            setMenuOpen(false);
                                        }}
                                        style={{ color: "red" }}
                                    >
                                        Draft
                                    </MenuItem>
                                </Menu>
                            </div>
                        </div>
                    </div>
                    <div className="flex_r gap-40 dashboard_product_statistic">
                        <div className="flex_c db_statisctic" style={{ width: "33%" }}>
                            <h1>Total Stock items</h1>
                            <p>{getData.stock + getData.sales.items}</p>
                        </div>
                        <div className="flex_c db_statisctic" style={{ width: "33%" }}>
                            <h1>Sales</h1>
                            <p>{getData.sales.items}</p>
                        </div>
                        <div
                            className="flex_c db_statisctic"
                            style={{ width: "33%", border: "none" }}
                        >
                            <h1>Sales</h1>
                            <p style={{ color: "#2CDA53" }}>$ {getData.sales.amount}</p>
                        </div>
                    </div>
                    <div className=" flex_r gap-20 dashboard_product_details_info">
                        <div className="flex_c gap-20 dashboard_pd_info">
                            <Input
                                type="text"
                                label={"Product Name*"}
                                id="productName"
                                placeholder="Product Name"
                                disabled
                                value={getData.name}
                                className="checkout_input"
                            />

                            <Input
                                id="shortDesc"
                                type="text"
                                label={"Short Description"}
                                placeholder="Short Description"
                                value={getData.shortDesc}
                                disabled
                                className="checkout_input"
                            />

                            <div className="flex_c gap-10">
                                <label className="label">Product Description</label>
                                <textarea
                                    className="custom_textarea checkout_input"
                                    placeholder="Product Description"
                                    value={getData.description}
                                    disabled
                                ></textarea>
                            </div>
                            <div className="flex_r gap-10 product_modal_group">
                                <div className="flex_c product_modal_group_item">
                                    <Input
                                        label={"Price*"}
                                        type="number"
                                        id="price"
                                        placeholder="Price"
                                        min={0}
                                        step={0.01}
                                        disabled
                                        value={getData.price}
                                        className="checkout_input"
                                    />
                                    <p className="add_newproduct_currencay_modal"> $</p>
                                </div>
                                <div className="product_modal_group_item">
                                    <Input
                                        type="number"
                                        label={"Stock*"}
                                        id="stock"
                                        placeholder="Stock"
                                        disabled
                                        value={getData.stock}
                                        min={0}
                                        className="checkout_input"
                                    />
                                </div>
                                <div className="product_modal_group_item">
                                    <Input
                                        type="text"
                                        label={"SKU*"}
                                        id="sku"
                                        placeholder="SKU"
                                        disabled
                                        value={getData.sku}
                                        className="checkout_input"
                                    />
                                </div>
                            </div>
                            <Input
                                type="text"
                                label={"Keywords"}
                                placeholder="keywords"
                                value={getData.keywords}
                                disabled
                                className="checkout_input"
                            />
                            {getData.status !== "deleted" &&
                                <div className="flex_r gap-20">
                                    <button
                                        className="product_Modal_btn_save"
                                        onClick={() => setOpenModal(true)}
                                    >
                                        Edit Product
                                    </button>
                                    <button
                                        className="product_Modal_btn_delete"
                                        onClick={() => handleDelete(getData._id)}
                                    >
                                        Delete
                                    </button>
                                    <Modal
                                        open={openModal}
                                        onClose={() => setOpenModal(false)}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                    >
                                        <Box sx={style} className="modal-content-wrapper">
                                            <Typography
                                                id="modal-modal-title"
                                                variant="h6"
                                                component="h2"
                                            >
                                                <div
                                                    className="flex_r "
                                                    style={{ justifyContent: "space-between" }}
                                                >
                                                    Edit Product
                                                    <LuX
                                                        onClick={() => setOpenModal(false)}
                                                        style={{ cursor: "pointer" }}
                                                    />
                                                </div>
                                            </Typography>
                                            <Typography
                                                id="modal-modal-description"
                                                sx={{
                                                    mt: 2,
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "20px",
                                                }}
                                            >
                                                <Input
                                                    type="text"
                                                    label={"Product Name*"}
                                                    id="productName"
                                                    placeholder="Product Name"
                                                    name="name"
                                                    value={productData?.name}
                                                    className="checkout_input"
                                                    onChange={handleChange}
                                                />
                                                <div className="flex_r gap-10 product_modal_group">
                                                    <div className="flex_c product_modal_group_item">
                                                        <Input
                                                            label={"Price*"}
                                                            type="number"
                                                            id="price"
                                                            placeholder="Price"
                                                            min={0}
                                                            step={0.01}
                                                            name="price"
                                                            value={productData?.price}
                                                            className="checkout_input"
                                                            onChange={handleChange}
                                                        />
                                                        <p className="add_newproduct_currencay_modal"> $</p>
                                                    </div>
                                                    <div className="product_modal_group_item">
                                                        <Input
                                                            type="number"
                                                            label={"Stock*"}
                                                            id="stock"
                                                            placeholder="Stock"
                                                            min={0}
                                                            name="stock"
                                                            value={productData?.stock}
                                                            className="checkout_input"
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className="product_modal_group_item">
                                                        <Input
                                                            type="text"
                                                            label={"SKU*"}
                                                            id="sku"
                                                            placeholder="SKU"
                                                            value={productData?.sku}
                                                            className="checkout_input"
                                                            name="sku"
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>

                                                <Input
                                                    type="text"
                                                    label={"Short Description*"}
                                                    id="productName"
                                                    placeholder="Short Description"
                                                    name="shortDesc"
                                                    value={productData?.shortDesc}
                                                    className="checkout_input"
                                                    onChange={handleChange}
                                                />
                                                <div className="flex_c gap-10">
                                                    <label className="label">Product Description*</label>
                                                    <textarea
                                                        className="custom_textarea checkout_input"
                                                        placeholder="Product Description"
                                                        name="description"
                                                        value={productData?.description}
                                                        onChange={handleChange}
                                                    ></textarea>
                                                </div>
                                                <Input
                                                    type="text"
                                                    label={"Keywords*"}
                                                    id="price"
                                                    placeholder="keywords"
                                                    name="keywords"
                                                    value={productData?.keywords}
                                                    className="checkout_input"
                                                    onChange={handleChange}
                                                />
                                                <p className="input_text">
                                                    Just keep a space between the words.
                                                </p>
                                                <label className="label_inputs">
                                                    <span className="label">Product Image*</span>
                                                    <div className="flex_r space_between image_input align_items_center">
                                                        <div className="flex_r gap-10 align_items_center">
                                                            {productData?.image ? (
                                                                <img
                                                                    src={productData?.image}
                                                                    alt={productData?.name}
                                                                />
                                                            ) : (
                                                                <div className="image_placeholder">
                                                                    <LuImagePlus />
                                                                </div>
                                                            )}
                                                            <p>Upload Image</p>
                                                        </div>
                                                        <input
                                                            id="productImage"
                                                            type="file"
                                                            hidden
                                                            onChange={handleChangeImage}
                                                        />
                                                        <LuUpload />
                                                    </div>
                                                </label>
                                                <div className="flex_r gap-20 product_Modal_btn">
                                                    <button
                                                        className="product_Modal_btn_cancel"
                                                        onClick={() => setOpenModal(false)}
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        className="product_Modal_btn_save"
                                                        onClick={() => {
                                                            setOpenModal(null);
                                                            updateProductDetails();
                                                        }}
                                                    >
                                                        Save Product
                                                    </button>
                                                </div>
                                            </Typography>
                                        </Box>
                                    </Modal>
                                </div>
                            }

                        </div>

                        <div className=" flex_c gap-20 dashboard_pd_img">
                            <label className="label">Poduct Image</label>
                            <img src={getData.image} alt="product name" />
                        </div>
                    </div>
                    {(updateStatus === "pending" || deleteStatus === "pending") && <Loading />}
                </div>
            </div>
        );
    }
};
