import { useMutation } from "@tanstack/react-query";
import { useAuth } from "Context/AuthContext";

export const useVerifyOtpApi = () => {
    const { verifyOtp } = useAuth();

    return useMutation({
        mutationFn: ({ otp }) => {
            return verifyOtp(otp);
        }
    });
};