import { baseURL } from "Components/Common/constance";
import axios from "axios";

const allCardsEndPoint = "/api/products/search";

export const getAllCards = async (query) => {
    try {
        const response = await axios.get(`${baseURL}${allCardsEndPoint}`, { params: query });
        return response.data.data;
    } catch (error) {
        return error.response.data;
    }
};
