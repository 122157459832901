export const switchStatusColor = (orderStatus) => {
  switch (orderStatus) {
    case "processing":
      return "#345EFF";
    case "canceled":
      return "#EE1D52";
    case "pending":
      return "#FDE461";
    case "completed":
      return "#2CDA53";
    case "published":
      return "#2CDA53";
    case "draft":
      return "#EE1D52";
    case "deleted":
      return "#EE1D52";
    default:
      return "black";
  }
};
