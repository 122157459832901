import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MdOutlineKeyboardDoubleArrowLeft } from "react-icons/md";
import './style.css';

export const CheckEmail = () => {
    const navigate = useNavigate();
    const handleNavigate = () => {
        navigate('/login');
    }

    return (
        <div className='main_container'>
            <div className='flex_c gap-40 auth_page'>
                <div className='flex_c gap-20'>
                    <h1>
                        Check Your Email
                    </h1>
                    <p>Please check your email inbox and click on the provided link to reset your
                        password . If you don’t receive email,<span className='checkemail_p_span'><u>Click here to resend</u></span>
                    </p>
                </div>
                <div className='checkemail_backarrow' onClick={handleNavigate}>
                    <MdOutlineKeyboardDoubleArrowLeft />Back to<u>Login</u>
                </div>
            </div>
        </div>
    )
}
