import React, { useState } from 'react';
import { Input } from 'Components';
import './style.css';
import { useSearchContext } from 'Context/SearchContext';
import { useNavigate } from 'react-router-dom';

export const Searchbar = () => {
    const navigate = useNavigate();
    const [searchKeyword, setSearchKeyword] = useState('');
    const { updateSearchTerm } = useSearchContext();

    const handleChange = (e) => {
        setSearchKeyword(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        navigate('/');
        updateSearchTerm(searchKeyword);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            console.log('Enter key pressed!');
        }
    };
    return (
        <form onSubmit={handleSubmit} className='flex_r searchbar_container'>
            <Input
                type="text"
                value={searchKeyword}
                onChange={handleChange}
                onKeyDown={handleKeyPress}
                className='login_input'
                placeholder={"Search"}
                style={{
                    gap: "0"
                }}
            />
            <div className='button_search_section'>
                <button type='submit' className='searchbar_button'>Search</button>
            </div>
        </form>
    )
}
