import { useMutation, useQuery } from "@tanstack/react-query";
import {
  addProduct,
  deleteProduct,
  getProductsAnalytics,
  getProductsTable,
  updateProduct,
  getProductById,
  uploadCSV,
} from "./ProductsApis";
import { useAuth } from "Context/AuthContext";
import { queryClient } from "App";

const productAnalyticsKey = "PRODUCT_ANALYTICS_KEY";
const productTableKey = "PRODUCT_TABLE_KEY";

export const useGetProductsAnalytics = () => {
  const auth = useAuth();
  const token = auth.authorizationToken();

  return useQuery({
    queryKey: [productAnalyticsKey],
    queryFn: () => getProductsAnalytics(token),
    cacheTime: 3600000,
    staleTime: 3600000,
    enabled: true,
    onError: (error) => {
      console.error('An error occurred:', error);
      throw new Error(error);
    },
  });
};

export const useGetProductsTable = (query) => {
  const auth = useAuth();
  const token = auth.authorizationToken();

  return useQuery({
    queryKey: [productTableKey, query],
    queryFn: () => getProductsTable(token, query),
    cacheTime: 3600000,
    staleTime: 3600000,
    enabled: true,
    onError: (error) => {
      console.error('An error occurred:', error);
      throw new Error(error);
    },
  });
};

export const useGetProductById = (productId) => {
  const auth = useAuth();
  const token = auth.authorizationToken();

  return useQuery({
    queryKey: [productTableKey, productId],
    queryFn: () => {
      return getProductById(token, productId);
    },
    cacheTime: 3600000,
    staleTime: 3600000,
    enabled: productId !== null,
    onError: (error) => {
      console.error('An error occurred:', error);
      throw new Error(error);
    },
  });
};

export const useUpdateProduct = () => {
  const auth = useAuth();
  const token = auth.authorizationToken();

  return useMutation({
    mutationFn: ({ productId, data }) => {
      return updateProduct(token, productId, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(productTableKey);
    },
  });
};

export const useDeleteProduct = () => {
  const auth = useAuth();
  const token = auth.authorizationToken();

  return useMutation({
    mutationFn: ({ productId }) => deleteProduct(token, productId),
    onSuccess: () => {
      queryClient.invalidateQueries(productTableKey);
    },
  });
};

export const useUploadCSV = () => {
  const auth = useAuth();
  const token = auth.authorizationToken();

  return useMutation({
    mutationFn: ({ formData }) => {
      return uploadCSV(token, formData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(productTableKey);
    },
  });
};

export const useAddProduct = () => {
  const auth = useAuth();
  const token = auth.authorizationToken();
  return useMutation({
    mutationFn: (formData) => {
      return addProduct(token, formData)
    },
    onSuccess: () => {
      queryClient.invalidateQueries(productTableKey);
    },
  });
};
