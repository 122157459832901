import React from 'react';
import { AllCards } from 'Pages';
import "./style.css";

export const Home = () => {
    return (
        <div className='flex_r main_container'>
            <AllCards />
        </div>
    )
}