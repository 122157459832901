import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "Components";
import { LuShoppingCart } from "react-icons/lu";
import "./style.css";

export const EmptyCart = () => {
    const navigate = useNavigate();
    const navigateToHome = () => {
        navigate("/");
    };
    return (
        <div className="main_container">
            <div className="flex_c align_items_center gap-40 auth_page shadow">
                <div className="flex_c gap-20 empty_cart">
                    <LuShoppingCart className="empty_cart_icon"/>
                    <h1>Your Cart is empty.</h1>
                    <p>
                        You don’t have any products in the cart yet. You will find a lot
                        of interesting products on our Shop page.
                    </p>
                    <Button name={"Go Shopping"} onClick={navigateToHome} />
                </div>
            </div>
        </div>
    );
};
