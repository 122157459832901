import { useAuth } from "Context/AuthContext";
import { getProfileApi, updatePassword, updateProfile } from "./profileApis";
import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "App";

const profileKey = ["PROFILE_KEY"];
const passwordKey = ["PASSWORD_KEY"];

export const useProfileApis = () => {
  const auth = useAuth();
  const token = auth.authorizationToken();

  return useQuery({
    queryKey: profileKey,
    queryFn: () => getProfileApi(token),
    cacheTime: 3600000,
    staleTime: 3600000,
    enabled: true,
    onError: (error) => {
      console.error('An error occurred:', error);
      throw new Error(error);
    },
  });
};

export const useUpdateProfile = () => {
  const auth = useAuth();
  const token = auth.authorizationToken();

  return useMutation({
    mutationFn: (data) => updateProfile(token, data),
    onSuccess: () => {
      queryClient.invalidateQueries(profileKey);
    },
  });
};

export const useUpdatePassword = () => {
  const auth = useAuth();
  const token = auth.authorizationToken();

  return useMutation({
    mutationFn: (data) => updatePassword(token, data),
    onSuccess: () => {
      queryClient.invalidateQueries(passwordKey);
    },
  });
};
