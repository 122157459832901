import React from "react";
import PaginationNumbers from "./pagination";
import { LuArrowLeft, LuArrowRight } from "react-icons/lu";

export const Table = ({
    heading,
    children,
    currentPage,
    totalPages,
    onSelectPage,
    onNextPage,
    onPreviousPage,
}) => {
    return (
        <table className="table">
            <thead>
                <tr>
                    {heading.map((head, index) => (
                        <th key={index}>{head}</th>
                    ))}
                </tr>
            </thead>
            <tbody>{children}</tbody>
            {totalPages && (
                <tfoot>
                    <tr>
                        <td colSpan={heading.length}>
                            <div className="table_footer flex_r gap-10 space_between align_items_center">
                                <div className="table_pages">
                                    Page {currentPage} of {totalPages}
                                </div>
                                <div className="table_pages">
                                    <PaginationNumbers
                                        currentPage={currentPage}
                                        totalPages={totalPages}
                                        onSelectPage={onSelectPage}
                                    />
                                </div>
                                <div className="table_pages_actions flex_r gap-10">
                                    <div
                                        className="table_footer_arrow"
                                        onClick={currentPage === 1 ? null : onPreviousPage}
                                    >
                                        <LuArrowLeft />
                                    </div>
                                    <div
                                        className="table_footer_arrow"
                                        onClick={currentPage === totalPages ? null : onNextPage}
                                    >
                                        <LuArrowRight />
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tfoot>
            )}
        </table>
    );
};
