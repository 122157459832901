import React from 'react';
import { color } from 'theme';

export const Button = ({ name, className, onClick, onSubmit, ...props }) => {
    return (
        <button className={className}
            onClick={onClick}
            onSubmit={onSubmit}
            style={{
                backgroundColor: color.primaryColor,
                padding: "16px 20px",
                color: "white",
                borderRadius: "8px",
                minWidth: "167px",
                border: "none",
            }}
            {...props}
        >
            {name}
        </button>
    )
}
