import React, { forwardRef, useState } from 'react';
import { LuEye, LuEyeOff } from 'react-icons/lu';

export const Input = forwardRef(({ label, className, placeholder, onChange, type, value, startIcon, ...props }, ref) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <label className={`${type === "checkbox" ? "container" : "label_inputs"}`}>
            {label && <span className='label'> {label} </span>}
            <input
                ref={ref}
                className={className}
                placeholder={placeholder}
                onChange={onChange}
                type={type === "password" ? showPassword ? "text" : type : type}
                value={value}
                {...props}
            />
            {type === "password" &&
                (showPassword ?
                    <LuEye
                        className='show_hide_icon'
                        onClick={() => setShowPassword(false)}
                        style={{ cursor: 'pointer', userSelect: 'none' }}
                    />
                    : <LuEyeOff
                        className='show_hide_icon'
                        onClick={() => setShowPassword(true)}
                        style={{ cursor: 'pointer', userSelect: 'none' }}
                    />)
            }
            {type === "checkbox" && <span className="checkmark"></span>}
        </label>
    )
}) 
