import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useCart } from "Context/CartContext";
import CircleCheckbox from "Components/Common/circleCheckBox";
import { Button, Input } from "Components";
import checkoutIMG from "Assets/cardimage.png";
import { usePostCheckout } from "./useCheckoutApis";
import { transformData } from "Helpers/transformData";
import "./style.css";
import { LuChevronLeft } from "react-icons/lu";

export const Checkout = () => {
  const navigate = useNavigate();
  const { cartItems, clearCart } = useCart();
  const [formData, setFormData] = useState({});
  const [isSameChecked, setIsSameChecked] = useState(true);
  const [isDifferentChecked, setIsDifferentChecked] = useState(false);
  const mutation = usePostCheckout();

  let subTotal = 0;
  let shippingFees = 5;
  cartItems.map((item) => (subTotal = +(item.price * item.quantity)));

  const handleSameCheckClick = () => {
    setIsSameChecked(true);
    setIsDifferentChecked(false);
  };
  const handleDifferentCheckClick = () => {
    setIsSameChecked(false);
    setIsDifferentChecked(true);
  };

  if (cartItems.length === 0) {
    return <Navigate to={"/404"} />;
  }

  const handleChange = (e) => {
    const fieldName = e?.target.name;
    const value = e?.target.value;
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = transformData(formData);
    if (isSameChecked) {
      data.shippingAddress = data.billingAddress;
    }
    data.paymentMethod = "cashOnDelivery";
    data.shipping = shippingFees;
    data.products = cartItems.map((item) => {
      return {
        productId: item._id,
        quantity: item.quantity,
        total: item.price * item.quantity,
      };
    });
    data.subTotal = subTotal;
    data.total = shippingFees + subTotal;
    try {
      const response = await mutation.mutateAsync(data);
      if (!response.success) {
        alert(response.message);
      } else {
        clearCart();
        navigate("/confirmed-order");
      }
    } catch (error) {
      alert(error.message);
    }
  };
  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="flex_c main_container">
      <div
        className="flex_r align_items_center"
        onClick={goBack}
        style={{ cursor: "pointer", paddingBottom: "30px" }}
      >
        <LuChevronLeft style={{ fontSize: "25px" }} />
        <p className="dashboard_title">Back</p>
      </div>
      <div style={{ width: "100%" }}>
        <h1 className="main_header">Checkout</h1>
        <div className="flex_r gap-20 checkout">
          <form className="flex_c checkout_forms" onSubmit={handleSubmit}>
            <div className="flex_c checkout_section">
              <h2 className="main_title">Billing Details</h2>
              <div className="flex_r gap-10 checkout_inputs">
                <div
                  className="checkout_input_container"
                  style={{ width: "100%" }}
                >
                  <Input
                    type="text"
                    label="Full Name*"
                    className="checkout_input"
                    placeholder="Full Name"
                    required
                    name={"customerInfo.name"}
                    value={formData["customerInfo.name"] || ""}
                    onChange={handleChange}
                  />
                </div>
                <div className="checkout_input_container">
                  <Input
                    type="text"
                    label="Phone*"
                    className="checkout_input"
                    placeholder="Phone"
                    required
                    name="customerInfo.phone"
                    value={formData["customerInfo.phone"] || ""}
                    onChange={handleChange}
                  />
                </div>
                <div className="checkout_input_container">
                  <Input
                    type="text"
                    label="Email"
                    className="checkout_input"
                    placeholder="Email"
                    name="customerInfo.email"
                    value={formData["customerInfo.email"] || ""}
                    onChange={handleChange}
                  />
                </div>
                <div className="checkout_input_container">
                  <Input
                    type="text"
                    label="City*"
                    className="checkout_input"
                    placeholder="City"
                    required
                    name="billingAddress.city"
                    value={formData["billingAddress.city"] || ""}
                    onChange={handleChange}
                  />
                </div>
                <div className="checkout_input_container">
                  <Input
                    type="text"
                    label="Street Address*"
                    className="checkout_input"
                    placeholder="Street Address"
                    required
                    name="billingAddress.street"
                    value={formData["billingAddress.street"] || ""}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="flex_c gap-40 checkout_section">
              <div className="flex_c gap-10">
                <h2 className="main_title"> Shipping Address</h2>
                <p className="main_subtitle">
                  Select the address that matches your card or payment method.
                </p>
              </div>
              <div className="flex_c gap-20 light_background checkout_container">
                <div className="flex_r gap-10 align_items_center">
                  <CircleCheckbox
                    isChecked={isSameChecked}
                    onClick={handleSameCheckClick}
                  />
                  <label htmlFor="sameAsBillingAddress">
                    Same as Billing address
                  </label>
                </div>
                <hr />
                <div className="flex_r gap-10 align_items_center">
                  <CircleCheckbox
                    isChecked={isDifferentChecked}
                    onClick={handleDifferentCheckClick}
                  />
                  <label htmlFor="sameAsBillingAddress">
                    Use a different shipping address
                  </label>
                </div>
              </div>
              {isDifferentChecked && (
                <div className="flex_r gap-20 align_items_center">
                  <div className="checkout_input_container">
                    <Input
                      type="text"
                      label="City*"
                      className="checkout_input"
                      placeholder="City"
                      required
                      name="shippingAddress.city"
                      value={formData["shippingAddress.city"] || ""}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="checkout_input_container">
                    <Input
                      type="text"
                      label="Street Address*"
                      className="checkout_input"
                      placeholder="Street Address"
                      required
                      name="shippingAddress.street"
                      value={formData["shippingAddress.street"] || ""}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="flex_c checkout_section">
              <h2 className="main_title"> Shipping Method</h2>
              <div className="flex_c gap-20 light_background checkout_container">
                <div>
                  <h4>Arrives by Monday, June 7</h4>
                </div>
                <hr />
                <div className="flex_c gap-10">
                  <div className="flex_r space_between">
                    <h4>Delivery Charges</h4>
                    <p>$ {shippingFees}</p>
                  </div>
                  <p>Additional fess may apply</p>
                </div>
              </div>
            </div>
            <div className="flex_c gap-20 checkout_section">
              <div className="flex_c">
                <h2 className="main_title"> Payment Method</h2>
                <p className="main_subtitle">
                  All transactions are secure and encrypted.
                </p>
              </div>
              <div className="flex_c gap-20 light_background checkout_container">
                <div className="flex_r gap-10 align_items_center">
                  <CircleCheckbox isChecked={true} />
                  <label htmlFor="sameAsBillingAddress">
                    <p> Cash on Delivery</p>
                    <p> Pay with cash upon delivery.</p>
                  </label>
                </div>
              </div>
            </div>
            <div>
              <Input
                type="text"
                label="Note"
                className="checkout_input"
                placeholder="Write a note"
                name="note"
                value={formData["note"] || ""}
                onChange={handleChange}
              />
            </div>
            <div style={{ marginTop: "20px" }}>
              <Button
                type="submit"
                name={"Complete Checkout"}
                // onClick={handleSubmit}
              />
            </div>
          </form>

          <div className="flex_c gap-20 checkout_summary">
            <h3 className="summary_header">Order Summary</h3>
            <div className="flex_c summary_subOrder">
              {cartItems.map((item) => (
                <div
                  key={item._id}
                  className="flex_r space_between align_items_center order_summary"
                >
                  <div className="flex_r gap-20 product_summary">
                    <img src={checkoutIMG} alt={item.name} />
                    <p>{item.name}</p>
                  </div>
                  <span>x {item.quantity}</span>
                  <p>$ {item.price}</p>
                </div>
              ))}
            </div>

            <div className="flex_c gap-20 fixed_bottom">
              <hr />
              <div className=" flex_r space_between">
                <h5 className="summary_subtitle">
                  Subtotal
                  <span>
                    ( {cartItems.length} item{cartItems.length !== 1 && "s"} )
                  </span>
                </h5>
                <p className="summary_subtitle">$ {subTotal}</p>
              </div>
              <hr />
              <div className=" flex_r space_between">
                <h5 className="summary_subtitle">Shipping</h5>
                <p className="summary_subtitle">$ {shippingFees}</p>
              </div>
              <hr />
              <div className=" flex_r space_between">
                <h5 className="summary_subtitle">Total </h5>
                <p className="summary_subtitle">${subTotal + shippingFees}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
