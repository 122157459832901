import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { color } from 'theme';
import { Button, Input, Loading } from 'Components';
import { useOnboardingApi } from './useOnboardingApi';
import { handleFileUpload } from 'Helpers/uploadImageApi';
import { useAuth } from 'Context/AuthContext';
import { LuImagePlus, LuUpload } from 'react-icons/lu';
import './style.css';

export const Onboarding = () => {
    const navigate = useNavigate();
    const auth = useAuth();
    const token = auth.authorizationToken();

    const [isLoading, setIsLoading] = useState(false);
    const [onboardingData, setOnboardingData] = useState({});

    const { mutateAsync: onboarding } = useOnboardingApi();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const response = await onboarding(onboardingData);
            if (!response.success) {
                alert(response.message);
                setIsLoading(false);
                return response
            }
            setIsLoading(false);
            navigate("/dashboard/products")
            return response;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    const handleImageChange = async (e) => {
        try {
            let response = await handleFileUpload(token, e.target.files[0]);
            setOnboardingData((prevData) => ({
                ...prevData,
                "logo": response.data.image,
            }));
        } catch (err) {
            console.err(err)
        }
    };
    const handleChange = async (e) => {
        const fieldName = e?.target.name;
        const value = e?.target.value;
        setOnboardingData((prevData) => ({
            ...prevData,
            [fieldName]: value,
        }));
    };

    return (
        <div className='main_container'>
            {isLoading ? <Loading /> : (
                <div className='flex_c gap-40 auth_page'>
                    <div className='flex_c gap-20'>
                        <h1>Onboarding</h1>
                        <p style={{ color: color.textColor }} >Enter your business info (you can change them later)</p>
                    </div>
                    <form className='flex_c gap-20' onSubmit={handleSubmit} >
                        <label className="label_inputs">
                            <span className="label">Comapny Logo*</span>
                            <div className="flex_r space_between image_input align_items_center">
                                <div className="flex_r gap-10 align_items_center">
                                    {onboardingData.logo ?
                                        <img src={onboardingData.logo} alt={onboardingData.name} />
                                        : <div className="image_placeholder">
                                            <LuImagePlus />
                                        </div>
                                    }
                                    <p>Upload Logo</p>
                                </div>
                                <input
                                    id="companyLogo"
                                    type="file"
                                    hidden
                                    name='logo'
                                    onChange={handleImageChange}
                                />
                                <LuUpload />
                            </div>
                        </label>
                        <Input
                            type="text"
                            label="Company Name*"
                            placeholder={"Name"}
                            className="login_input"
                            name="name"
                            onChange={handleChange}
                            value={onboardingData.name || ""}
                        />
                        <div className='flex_r'>
                            <Button name="Continue to dashboard" type="submit" />
                        </div>
                    </form>
                </div>
            )}
        </div>
    )
}
