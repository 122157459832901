import React from 'react';
import './style.css';

export const Logo = ({ img, alt, className }) => {
    return (
        <div>
            <img src={img} alt={alt} className={className} style={{
                maxWidth: "60px",
                borderRadius: "50px",
                objectFit: 'cover',
                objectPosition: 'center'
            }} />
        </div>
    )
}