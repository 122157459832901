import React from "react";
import { useNavigate } from "react-router";
import { useCart } from "Context/CartContext";
import { LuShoppingCart } from "react-icons/lu";
import "./style.css";

export const Card = ({ data }) => {
  const { _id, name, price, image, stock } = data;
  const navigate = useNavigate();
  const { addToCart } = useCart();

  return (
    <div className="flex_c card_container">
      <div className="card_image" onClick={() => navigate(`/products/${_id}`)}>
        <img src={image} alt="cardImage" />
      </div>
      <div className="flex_r space_between card_info">
        <h5>{name}</h5>
        <p>${price}</p>
      </div>
      {stock > 0 ? (
        <div
          className="card_icon"
          onClick={() => addToCart({ _id, name, image, price, stock })}
        >
          <LuShoppingCart />
        </div>
      ) : (
        <div className="card_icon_outofstock">Out Of Stock</div>
      )}
    </div>
  );
};
