import { useQuery } from "@tanstack/react-query";
import { getSingleCardById } from "./SingleCardApis";

export const useGetSingleCardById = (productId) => {
    const singleCardKey = ["SINGLE_CARD_ID", productId];
    return useQuery({
        queryKey: singleCardKey,
        queryFn: () => getSingleCardById(productId),
        enabled: !!productId, //to call the api one time
        cacheTime: 3600000,
        staleTime: 3600000,
    });
};
