import { baseURL } from "Components/Common/constance";
import axios from "axios";

const onboardingEndPoints = "/api/auth/onboarding";

export const updateOnboarding = async (authToken, data) => {
    try {
        const response = await axios.put(
            `${baseURL}${onboardingEndPoints}`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};