import { baseURL } from "Components/Common/constance";
import axios from "axios";

const profileApi = "/api/users/profile";
const updateProfileApi = "/api/users/profile";
const updatePasswordApi = "/api/users/profile/password/";

export const getProfileApi = async (authToken) => {
  try {
    const response = await axios.get(`${baseURL}${profileApi}`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response.data.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const updateProfile = async (authToken, data) => {
  try {
    const response = await axios.put(`${baseURL}${updateProfileApi}`, data, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const updatePassword = async (authToken, data) => {
  try {
    const response = await axios.put(`${baseURL}${updatePasswordApi}`, data, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
