import { baseURL } from "Components/Common/constance";
import axios from "axios";
const uploadEndpoint = "/api/services/upload"

export const uploadImage = async (authToken, formData) => {
    try {
        const response = await axios.post(
            `${baseURL}${uploadEndpoint}`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${authToken}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

export const handleFileUpload = async (token, file) => {
    const formData = new FormData();
    formData.append('file', file);
    try {
        const response = await uploadImage(token, formData);
        if (!response.success) {
            alert(response.message);
        }
        return response;
    } catch (error) {
        console.error(error);
        return null;
    }
};