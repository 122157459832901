import { baseURL } from "Components/Common/constance";
import axios from "axios";

const productDetailsEndPoints = "/api/products/admin/";
const productUpdateStatusEnddPoints = "/api/products";

export const getProductDetailsById = async (authToken, productId) => {
    try {
        const response = await axios.get(
            `${baseURL}${productDetailsEndPoints}${productId}`,
            {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            }
        );
        return response.data.data;
    } catch (error) {
        throw new Error(error.response.data.message);
    }
};

export const updateProduct = async (authToken, productId, data) => {
    try {
        const response = await axios.put(
            `${baseURL}${productUpdateStatusEnddPoints}/${productId}`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        return error.response.data
    }
};