import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { LuBoxes, LuLogOut, LuShoppingBag } from "react-icons/lu";
import "./style.css";
import { useAuth } from "Context/AuthContext";

export const SideBar = () => {
    const navigate = useNavigate();
    const auth = useAuth();

    const handleSignOut = () => {
        auth.logout();
        navigate("../login");
    };

    return (
        <div className="sidebar_container">
            <div className="flex_c sidebar_items">
                <NavLink to="/dashboard/products" className={({ isActive }) =>
                    ["flex_r gap-10 sidebar_item", isActive ? "active" : ""].join(" ")}>
                    <LuBoxes className="sidebar_icons" />
                    Products
                </NavLink>
                <NavLink to="/dashboard/orders" className={({ isActive }) =>
                    ["flex_r gap-10 sidebar_item", isActive ? "active" : ""].join(" ")}>
                    <LuShoppingBag className="sidebar_icons" />
                    Orders
                </NavLink>
                <div
                    className="flex_r gap-10 sidebar_item"
                    onClick={handleSignOut}
                >
                    <LuLogOut className="sidebar_icons" />
                    Signout
                </div>
            </div>
        </div>
    );
};
